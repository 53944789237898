var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState, useRef } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Formik, Form as FormikForm, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Spinner, Alert, Button, Table, Modal, Form, Card } from 'react-bootstrap';
import axios from 'axios';
import { PreDrilledHoles } from '@mixam-platform/mixam-types';
import ConfirmationModal from './ConfirmationModal';
var ItemTypes = {
    ROW: 'row',
};
var validationSchema = Yup.object().shape({
    preDrilledHoleOptions: Yup.array()
        .of(Yup.object().shape({
        preDrilledHoles: Yup.mixed()
            .oneOf(Object.values(PreDrilledHoles), 'Invalid option')
            .required('Pre-drilled hole type is required'),
        santaDefault: Yup.boolean(),
    }))
        .test('has-valid-options', 'Options must be unique and have a default.', function (options) {
        if (!options || options.length === 0)
            return true; // Allow empty array without validation
        var hasUniqueOptions = options.map(function (o) { return o.preDrilledHoles; }).length === new Set(options.map(function (o) { return o.preDrilledHoles; })).size;
        var hasOneDefault = options.filter(function (o) { return o.santaDefault; }).length === 1;
        return hasUniqueOptions && hasOneDefault;
    }),
});
var PreDrilledHolesMetaDataEditor = function (_a) {
    var productId = _a.productId, subProductId = _a.subProductId, santaType = _a.santaType;
    var _b = useState(false), isLoading = _b[0], setLoading = _b[1];
    var _c = useState(null), error = _c[0], setError = _c[1];
    var _d = useState(null), successMessage = _d[0], setSuccessMessage = _d[1];
    var _e = useState(null), metadataDocumentWrapper = _e[0], setMetadataDocumentWrapper = _e[1];
    var _f = useState(false), useCustom = _f[0], setUseCustom = _f[1];
    var _g = useState(false), showModal = _g[0], setShowModal = _g[1];
    var _h = useState(false), showConfirmationModal = _h[0], setShowConfirmationModal = _h[1];
    var _j = useState(null), editIndex = _j[0], setEditIndex = _j[1];
    var _k = useState({
        preDrilledHoles: PreDrilledHoles.NONE,
        santaDefault: false,
    }), newOption = _k[0], setNewOption = _k[1];
    useEffect(function () {
        setLoading(true);
        axios
            .get("/api/admin/metadata/product/pre-drilled-holes/products/".concat(productId, "/sub-products/").concat(subProductId, "/santa-types/").concat(santaType))
            .then(function (response) {
            setMetadataDocumentWrapper(response.data);
            if (subProductId !== 0) {
                setUseCustom(response.data.productMetadataDocument !== null);
            }
            setLoading(false);
        })
            .catch(function () {
            setLoading(false);
            setError('Failed to load pre-drilled holes metadata document.');
        });
    }, [productId, subProductId, santaType]);
    var handleSave = function (values, _a) {
        var setSubmitting = _a.setSubmitting;
        if (!useCustom && (metadataDocumentWrapper === null || metadataDocumentWrapper === void 0 ? void 0 : metadataDocumentWrapper.productMetadataDocument)) {
            setShowConfirmationModal(true);
            setSubmitting(false);
            return;
        }
        savePreDrilledHolesMetadata(values, setSubmitting);
    };
    var savePreDrilledHolesMetadata = function (values, setSubmitting) {
        var _a;
        setLoading(true);
        setError(null);
        setSuccessMessage(null);
        var activeDocument = useCustom
            ? metadataDocumentWrapper === null || metadataDocumentWrapper === void 0 ? void 0 : metadataDocumentWrapper.productMetadataDocument
            : metadataDocumentWrapper === null || metadataDocumentWrapper === void 0 ? void 0 : metadataDocumentWrapper.defaultProductMetadataDocument;
        var newDocument = activeDocument
            ? __assign({}, activeDocument) : {
            shopId: (_a = metadataDocumentWrapper === null || metadataDocumentWrapper === void 0 ? void 0 : metadataDocumentWrapper.defaultProductMetadataDocument) === null || _a === void 0 ? void 0 : _a.shopId,
            productId: productId,
            subProductId: subProductId,
            santaType: santaType,
        };
        var saveRequest = {
            useDefault: !useCustom,
            preDrilledHolesMetadataDocument: useCustom
                ? __assign(__assign({}, newDocument), { preDrilledHoleOptions: values.preDrilledHoleOptions }) : null,
        };
        axios
            .post("/api/admin/metadata/product/pre-drilled-holes/products/".concat(productId, "/sub-products/").concat(subProductId, "/santa-types/").concat(santaType), saveRequest)
            .then(function (response) {
            setMetadataDocumentWrapper(response.data);
            setLoading(false);
            setSubmitting(false);
            setSuccessMessage('Pre-Drilled Holes Metadata saved successfully!');
        })
            .catch(function (error) {
            var _a;
            setLoading(false);
            setSubmitting(false);
            setError("Failed to save pre-drilled holes metadata: ".concat(((_a = error.response) === null || _a === void 0 ? void 0 : _a.data) || error.message));
        });
    };
    var handleCopyFromDefault = function () {
        if (metadataDocumentWrapper === null || metadataDocumentWrapper === void 0 ? void 0 : metadataDocumentWrapper.defaultProductMetadataDocument) {
            var defaultData = metadataDocumentWrapper.defaultProductMetadataDocument;
            var updatedCustomDocument = __assign(__assign({}, metadataDocumentWrapper.productMetadataDocument || {
                shopId: defaultData.shopId,
                productId: productId,
                subProductId: subProductId,
                santaType: santaType,
            }), { preDrilledHoleOptions: defaultData.preDrilledHoleOptions || [] });
            setMetadataDocumentWrapper(__assign(__assign({}, metadataDocumentWrapper), { productMetadataDocument: updatedCustomDocument }));
        }
    };
    var getAvailableOptions = function (values) {
        var existingOptions = values.preDrilledHoleOptions.map(function (option) { return option.preDrilledHoles; });
        return Object.values(PreDrilledHoles).map(function (holeType) { return ({
            value: holeType,
            label: holeType,
            disabled: existingOptions.includes(holeType),
        }); });
    };
    var moveRow = function (dragIndex, hoverIndex, setFieldValue, values) {
        var updatedOptions = __spreadArray([], values.preDrilledHoleOptions, true);
        var draggedOption = updatedOptions.splice(dragIndex, 1)[0];
        updatedOptions.splice(hoverIndex, 0, draggedOption);
        setFieldValue('preDrilledHoleOptions', updatedOptions);
    };
    var DraggableRow = function (_a) {
        var index = _a.index, option = _a.option, setFieldValue = _a.setFieldValue, values = _a.values;
        var ref = useRef(null);
        var _b = useDrop({
            accept: ItemTypes.ROW,
            hover: function (draggedItem) {
                if (!ref.current)
                    return;
                if (draggedItem.index !== index) {
                    moveRow(draggedItem.index, index, setFieldValue, values);
                    draggedItem.index = index;
                }
            },
        }), drop = _b[1];
        var _c = useDrag({
            type: ItemTypes.ROW,
            item: { type: ItemTypes.ROW, index: index },
            collect: function (monitor) { return ({
                isDragging: monitor.isDragging(),
            }); },
        }), isDragging = _c[0].isDragging, drag = _c[1];
        drag(drop(ref));
        return (React.createElement("tr", { ref: ref, style: { opacity: isDragging ? 0.5 : 1 } },
            React.createElement("td", null, option.preDrilledHoles),
            React.createElement("td", { className: "text-center" },
                React.createElement(Form.Check, { type: "radio", name: "santaDefault", checked: option.santaDefault, onChange: function () {
                        var updatedOptions = values.preDrilledHoleOptions.map(function (opt, i) { return (__assign(__assign({}, opt), { santaDefault: i === index })); });
                        setFieldValue('preDrilledHoleOptions', updatedOptions);
                    }, disabled: !isEditable })),
            React.createElement("td", { className: "text-end" },
                React.createElement(Button, { className: "btn-sm me-2", variant: "primary", onClick: function () {
                        setEditIndex(index);
                        setNewOption(option);
                        setShowModal(true);
                    }, disabled: !isEditable }, "Edit"),
                React.createElement(Button, { className: "btn-sm", variant: "danger", onClick: function () {
                        var updatedOptions = __spreadArray([], values.preDrilledHoleOptions, true);
                        updatedOptions.splice(index, 1);
                        setFieldValue('preDrilledHoleOptions', updatedOptions);
                    }, disabled: !isEditable }, "Delete"))));
    };
    var renderTable = function (values, setFieldValue) { return (React.createElement("div", { className: "mb-4" },
        React.createElement(Table, { bordered: true, hover: true, variant: "light" },
            React.createElement("thead", null,
                React.createElement("tr", null,
                    React.createElement("th", null, "Pre-Drilled Hole Type"),
                    React.createElement("th", null, "Default"),
                    React.createElement("th", null, "Actions"))),
            React.createElement("tbody", null, values.preDrilledHoleOptions.length > 0 ? (values.preDrilledHoleOptions.map(function (option, index) { return (React.createElement(DraggableRow, { key: index, index: index, option: option, setFieldValue: setFieldValue, values: values })); })) : (React.createElement("tr", null,
                React.createElement("td", { colSpan: 3, className: "text-center" }, "No options available."))))),
        React.createElement("div", { className: "text-end" },
            React.createElement(Button, { variant: "secondary", onClick: function () {
                    var _a;
                    setEditIndex(null);
                    var defaultOption = ((_a = getAvailableOptions(values).find(function (option) { return !option.disabled; })) === null || _a === void 0 ? void 0 : _a.value) || PreDrilledHoles.NONE;
                    setNewOption({
                        preDrilledHoles: defaultOption,
                        santaDefault: false,
                    });
                    setShowModal(true);
                }, disabled: !isEditable }, "Add Option")),
        React.createElement(ErrorMessage, { name: "preDrilledHoleOptions", component: "div", className: "text-danger" }))); };
    if (isLoading) {
        return React.createElement(Spinner, { animation: "grow", variant: "primary" });
    }
    if (!metadataDocumentWrapper) {
        return React.createElement(Alert, { variant: "danger" }, "No Pre-Drilled Holes Metadata available");
    }
    var activeDocument = useCustom
        ? metadataDocumentWrapper === null || metadataDocumentWrapper === void 0 ? void 0 : metadataDocumentWrapper.productMetadataDocument
        : metadataDocumentWrapper === null || metadataDocumentWrapper === void 0 ? void 0 : metadataDocumentWrapper.defaultProductMetadataDocument;
    var isEditable = useCustom || subProductId === 0;
    return (React.createElement(DndProvider, { backend: HTML5Backend },
        React.createElement(Formik, { initialValues: {
                preDrilledHoleOptions: (activeDocument === null || activeDocument === void 0 ? void 0 : activeDocument.preDrilledHoleOptions) || [],
            }, enableReinitialize: true, onSubmit: handleSave, validationSchema: validationSchema }, function (_a) {
            var isSubmitting = _a.isSubmitting, values = _a.values, setFieldValue = _a.setFieldValue, setSubmitting = _a.setSubmitting;
            return (React.createElement(React.Fragment, null,
                React.createElement(Card, { className: "p-4 shadow-sm bg-light" },
                    React.createElement(FormikForm, null,
                        React.createElement("h5", { className: "mb-4" }, "Pre-Drilled Holes Metadata"),
                        successMessage && (React.createElement(Alert, { variant: "success", onClose: function () { return setSuccessMessage(null); }, dismissible: true }, successMessage)),
                        error && (React.createElement(Alert, { variant: "danger", onClose: function () { return setError(null); }, dismissible: true }, error)),
                        subProductId !== 0 && (React.createElement(Form.Group, { className: "mb-4" },
                            React.createElement(Form.Check, { type: "radio", label: "Use Default Metadata", checked: !useCustom, onChange: function () { return setUseCustom(false); } }),
                            React.createElement(Form.Check, { type: "radio", label: "Customize Metadata", checked: useCustom, onChange: function () { return setUseCustom(true); } }),
                            React.createElement("hr", null),
                            useCustom && (React.createElement(Button, { className: "mt-1 btn-sm", variant: "secondary", onClick: handleCopyFromDefault }, "Copy Default Values")))),
                        renderTable(values, setFieldValue),
                        React.createElement(Button, { type: "submit", disabled: isSubmitting, variant: "primary", className: "mt-4" }, "Save Changes"))),
                React.createElement(ConfirmationModal, { show: showConfirmationModal, onHide: function () { return setShowConfirmationModal(false); }, onConfirm: function () {
                        setShowConfirmationModal(false);
                        savePreDrilledHolesMetadata(values, setSubmitting);
                    } }),
                React.createElement(Modal, { show: showModal, onHide: function () { return setShowModal(false); } },
                    React.createElement(Modal.Header, { closeButton: true },
                        React.createElement(Modal.Title, null, editIndex !== null ? 'Edit Option' : 'Add New Option')),
                    React.createElement(Modal.Body, null,
                        React.createElement(Form, null,
                            React.createElement(Form.Group, { className: "mb-3" },
                                React.createElement(Form.Label, null, "Pre-Drilled Hole Type"),
                                React.createElement(Form.Control, { as: "select", value: newOption.preDrilledHoles, onChange: function (e) {
                                        return setNewOption(function (prev) { return (__assign(__assign({}, prev), { preDrilledHoles: e.target.value })); });
                                    } }, getAvailableOptions(values).map(function (option) { return (React.createElement("option", { key: option.value, value: option.value, disabled: option.disabled }, option.label)); }))),
                            React.createElement(Form.Group, { className: "mb-3" },
                                React.createElement(Form.Check, { type: "checkbox", label: "Set as Default", checked: newOption.santaDefault, onChange: function (e) {
                                        return setNewOption(function (prev) { return (__assign(__assign({}, prev), { santaDefault: e.target.checked })); });
                                    } })))),
                    React.createElement(Modal.Footer, null,
                        React.createElement(Button, { variant: "secondary", onClick: function () { return setShowModal(false); } }, "Close"),
                        React.createElement(Button, { variant: "primary", onClick: function () {
                                var updatedOptions = __spreadArray([], values.preDrilledHoleOptions, true);
                                if (editIndex !== null) {
                                    updatedOptions[editIndex] = newOption;
                                }
                                else {
                                    updatedOptions.push(newOption);
                                }
                                setFieldValue('preDrilledHoleOptions', updatedOptions);
                                setShowModal(false);
                            } }, editIndex !== null ? 'Save Changes' : 'Add Option')))));
        })));
};
export default PreDrilledHolesMetaDataEditor;
