var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useRef } from 'react';
import { useFormikContext, Formik, Form as FormikForm } from 'formik';
import * as Yup from 'yup';
import { Form, Button, Table, Modal } from 'react-bootstrap';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
var ItemTypes = {
    ROW: 'row',
};
var HeadAndTailBandOptionsEditor = function (_a) {
    var isEditable = _a.isEditable, headAndTailBands = _a.headAndTailBands;
    var _b = useFormikContext(), values = _b.values, setFieldValue = _b.setFieldValue;
    var _c = React.useState(false), showModal = _c[0], setShowModal = _c[1];
    var options = values.headAndTailBandOptions || [];
    var moveRow = function (dragIndex, hoverIndex) {
        var updatedOptions = __spreadArray([], options, true);
        var removed = updatedOptions.splice(dragIndex, 1)[0];
        updatedOptions.splice(hoverIndex, 0, removed);
        setFieldValue('headAndTailBandOptions', updatedOptions);
    };
    var initialModalValues = {
        headAndTailBand: '',
        colourCode: '',
        santaDefault: false,
    };
    var validationSchema = Yup.object().shape({
        headAndTailBand: Yup.string().required('Head and Tail Band is required'),
        colourCode: Yup.string().required('Colour Code is required'),
        santaDefault: Yup.boolean(),
    });
    var handleAddOption = function (values) {
        var updatedOptions = __spreadArray([], options, true);
        // If santaDefault is true, unset other options
        if (values.santaDefault) {
            updatedOptions.forEach(function (opt) {
                opt.santaDefault = false;
            });
        }
        updatedOptions.push(values);
        setFieldValue('headAndTailBandOptions', updatedOptions);
        setShowModal(false);
    };
    return (React.createElement("div", { className: "mb-4" },
        React.createElement("h5", null, "Head and Tail Band Options"),
        React.createElement(DndProvider, { backend: HTML5Backend },
            React.createElement(Table, { bordered: true, hover: true, variant: "light" },
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("th", null, "Head and Tail Band"),
                        React.createElement("th", null, "Colour Code"),
                        React.createElement("th", null, "Default"),
                        React.createElement("th", null, "Actions"))),
                React.createElement("tbody", null, options.length > 0 ? (options.map(function (option, index) { return (React.createElement(DraggableRow, { key: "head-tail-band-option-".concat(option.headAndTailBand, "-").concat(option.santaDefault, "-").concat(option.colourCode), index: index, option: option, moveRow: moveRow, setFieldValue: setFieldValue, options: options, isEditable: isEditable })); })) : (React.createElement("tr", null,
                    React.createElement("td", { colSpan: 4, className: "text-center" }, "No head and tail band options available.")))))),
        React.createElement("div", { className: "text-end" },
            React.createElement(Button, { variant: "secondary", onClick: function () {
                    setShowModal(true);
                }, disabled: !isEditable }, "Add Head and Tail Band Option")),
        React.createElement(Modal, { show: showModal, onHide: function () { return setShowModal(false); } },
            React.createElement(Formik, { initialValues: initialModalValues, validationSchema: validationSchema, onSubmit: function (values) { return handleAddOption(values); } }, function (_a) {
                var values = _a.values, handleChange = _a.handleChange, handleSubmit = _a.handleSubmit, errors = _a.errors, touched = _a.touched, setFieldValue = _a.setFieldValue;
                return (React.createElement(React.Fragment, null,
                    React.createElement(Modal.Header, { closeButton: true },
                        React.createElement(Modal.Title, null, "Add New Head and Tail Band Option")),
                    React.createElement(Modal.Body, null,
                        React.createElement(FormikForm, null,
                            React.createElement(Form.Group, { className: "mb-3" },
                                React.createElement(Form.Label, null, "Head and Tail Band"),
                                React.createElement(Form.Control, { as: "select", name: "headAndTailBand", value: values.headAndTailBand, onChange: function (e) {
                                        handleChange(e);
                                        // Clear validation error on change
                                        if (errors.headAndTailBand) {
                                            setFieldValue('headAndTailBand', e.target.value);
                                        }
                                    }, isInvalid: touched.headAndTailBand && !!errors.headAndTailBand },
                                    React.createElement("option", { value: "" }, "Select Head and Tail Band"),
                                    headAndTailBands.map(function (band) { return (React.createElement("option", { key: "head-tail-band-".concat(band), value: band, disabled: options.some(function (opt) {
                                            return opt.headAndTailBand === band;
                                        }) }, band)); })),
                                React.createElement(Form.Control.Feedback, { type: "invalid" }, errors.headAndTailBand)),
                            React.createElement(Form.Group, { className: "mb-3" },
                                React.createElement(Form.Label, null, "Colour Code"),
                                React.createElement(Form.Control, { type: "text", name: "colourCode", value: values.colourCode, onChange: handleChange, isInvalid: touched.colourCode && !!errors.colourCode }),
                                React.createElement(Form.Control.Feedback, { type: "invalid" }, errors.colourCode)),
                            React.createElement(Form.Group, { className: "mb-3" },
                                React.createElement(Form.Check, { type: "checkbox", label: "Set as Default", name: "santaDefault", checked: values.santaDefault, onChange: function (e) {
                                        setFieldValue('santaDefault', e.target.checked);
                                    } })))),
                    React.createElement(Modal.Footer, null,
                        React.createElement(Button, { variant: "secondary", onClick: function () { return setShowModal(false); } }, "Close"),
                        React.createElement(Button, { variant: "primary", onClick: function () { return handleSubmit(); }, disabled: !isEditable }, "Add Head and Tail Band Option"))));
            }))));
};
export default HeadAndTailBandOptionsEditor;
/**
 * Draggable Row Component
 */
var DraggableRow = function (_a) {
    var index = _a.index, option = _a.option, moveRow = _a.moveRow, setFieldValue = _a.setFieldValue, options = _a.options, isEditable = _a.isEditable;
    var ref = useRef(null);
    var _b = useDrop({
        accept: ItemTypes.ROW,
        hover: function (item, monitor) {
            if (!ref.current)
                return;
            var dragIndex = item.index;
            var hoverIndex = index;
            if (dragIndex === hoverIndex)
                return;
            var hoverBoundingRect = ref.current.getBoundingClientRect();
            var hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            var clientOffset = monitor.getClientOffset();
            if (!clientOffset)
                return;
            var hoverClientY = clientOffset.y - hoverBoundingRect.top;
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY)
                return;
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY)
                return;
            moveRow(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    }), drop = _b[1];
    var _c = useDrag({
        type: ItemTypes.ROW,
        item: { index: index },
        collect: function (monitor) { return ({
            isDragging: monitor.isDragging(),
        }); },
    }), isDragging = _c[0].isDragging, drag = _c[1];
    drag(drop(ref));
    return (React.createElement("tr", { ref: ref, style: { opacity: isDragging ? 0 : 1 } },
        React.createElement("td", null, option.headAndTailBand),
        React.createElement("td", null, option.colourCode),
        React.createElement("td", null,
            React.createElement(Form.Check, { type: "radio", name: "headAndTailBandOptions.santaDefault", checked: option.santaDefault, onChange: function () {
                    var updatedOptions = options.map(function (opt, idx) { return (__assign(__assign({}, opt), { santaDefault: idx === index })); });
                    setFieldValue('headAndTailBandOptions', updatedOptions);
                }, disabled: !isEditable })),
        React.createElement("td", { className: "text-end" },
            React.createElement(Button, { className: "btn-sm", variant: "danger", onClick: function () {
                    var updatedOptions = __spreadArray([], options, true);
                    updatedOptions.splice(index, 1);
                    setFieldValue('headAndTailBandOptions', updatedOptions);
                }, disabled: !isEditable }, "Delete"))));
};
