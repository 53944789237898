import React from 'react';
import { Form, Button, Table, Modal, Alert } from 'react-bootstrap';
import { Formik, Form as FormikForm } from 'formik';
import * as Yup from 'yup';
import { SubstrateDesign } from '@mixam-platform/mixam-types';
var RequiredSubstrateEditor = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    var isEditable = _a.isEditable, substrateTypes = _a.substrateTypes, requiredSubstrate = _a.requiredSubstrate, setFieldValue = _a.setFieldValue;
    var _o = React.useState(false), showModal = _o[0], setShowModal = _o[1];
    // Determine if we're editing or adding
    var isEditing = requiredSubstrate != null;
    // Initial values for the form
    var initialValues = {
        substrateType: 'SILK',
        colour: 'NONE',
        weight: '1',
    };
    if (isEditing && requiredSubstrate) {
        var substrateTypeObj = substrateTypes.find(function (st) { return st.id === requiredSubstrate.typeId; });
        var colourObj = substrateTypeObj === null || substrateTypeObj === void 0 ? void 0 : substrateTypeObj.substrateColours.find(function (c) { return c.id === requiredSubstrate.colourId; });
        var weightObj = colourObj === null || colourObj === void 0 ? void 0 : colourObj.weights.find(function (w) { return w.id === requiredSubstrate.weightId; });
        initialValues.substrateType = (substrateTypeObj === null || substrateTypeObj === void 0 ? void 0 : substrateTypeObj.substrateType) || '';
        initialValues.colour = (colourObj === null || colourObj === void 0 ? void 0 : colourObj.substrateColour) || '';
        initialValues.weight = (weightObj === null || weightObj === void 0 ? void 0 : weightObj.id.toString()) || ''; // Ensure weight is string for select
    }
    // Validation schema
    var validationSchema = Yup.object().shape({
        substrateType: Yup.string().required('Substrate Type is required'),
        colour: Yup.string().required('Colour is required'),
        weight: Yup.string().required('Weight is required'), // Changed to string
    });
    // Handler for form submission
    var handleSubmit = function (values, _a) {
        var setSubmitting = _a.setSubmitting;
        var substrateTypeObj = substrateTypes.find(function (st) { return st.substrateType === values.substrateType; });
        if (!substrateTypeObj) {
            alert('Invalid Substrate Type selected.');
            setSubmitting(false);
            return;
        }
        var colourObj = substrateTypeObj.substrateColours.find(function (c) { return c.substrateColour === values.colour; });
        if (!colourObj) {
            alert('Invalid Colour selected.');
            setSubmitting(false);
            return;
        }
        var weightObj = colourObj.weights.find(function (w) { return w.id === Number(values.weight); });
        if (!weightObj) {
            alert('Invalid Weight selected.');
            setSubmitting(false);
            return;
        }
        var newRequiredSubstrate = {
            typeId: substrateTypeObj.id,
            colourId: colourObj.id,
            weightId: weightObj.id,
            design: SubstrateDesign.NONE, // Set to NONE as per requirement
        };
        setFieldValue('requiredSubstrate', newRequiredSubstrate);
        setShowModal(false);
        setSubmitting(false);
    };
    // Helper to fetch available colours based on substrate type
    var getAvailableColours = function (substrateType) {
        var substrateTypeObj = substrateTypes.find(function (st) { return st.substrateType === substrateType; });
        return (substrateTypeObj === null || substrateTypeObj === void 0 ? void 0 : substrateTypeObj.substrateColours) || [];
    };
    // Helper to fetch available weights based on substrate type and colour
    var getAvailableWeights = function (substrateType, colour) {
        var substrateTypeObj = substrateTypes.find(function (st) { return st.substrateType === substrateType; });
        var colourObj = substrateTypeObj === null || substrateTypeObj === void 0 ? void 0 : substrateTypeObj.substrateColours.find(function (c) { return c.substrateColour === colour; });
        return (colourObj === null || colourObj === void 0 ? void 0 : colourObj.weights) || [];
    };
    return (React.createElement("div", { className: "mb-3" },
        React.createElement("h6", null, "Required Substrate"),
        requiredSubstrate ? (React.createElement(Table, { bordered: true, hover: true, variant: "light", size: "sm" },
            React.createElement("thead", null,
                React.createElement("tr", null,
                    React.createElement("th", null, "Substrate Type"),
                    React.createElement("th", null, "Colour"),
                    React.createElement("th", null, "Weight"),
                    React.createElement("th", null, "Design"),
                    React.createElement("th", null, "Actions"))),
            React.createElement("tbody", null,
                React.createElement("tr", null,
                    React.createElement("td", null, ((_b = substrateTypes.find(function (st) { return st.id === requiredSubstrate.typeId; })) === null || _b === void 0 ? void 0 : _b.label) || 'Unknown Type'),
                    React.createElement("td", null, ((_d = (_c = substrateTypes
                        .find(function (st) { return st.id === requiredSubstrate.typeId; })) === null || _c === void 0 ? void 0 : _c.substrateColours.find(function (c) { return c.id === requiredSubstrate.colourId; })) === null || _d === void 0 ? void 0 : _d.name) || 'Unknown Colour'),
                    React.createElement("td", null, ((_f = (_e = substrateTypes
                        .find(function (st) { return st.id === requiredSubstrate.typeId; })) === null || _e === void 0 ? void 0 : _e.substrateColours.find(function (c) { return c.id === requiredSubstrate.colourId; })) === null || _f === void 0 ? void 0 : _f.weights.find(function (w) { return w.id === requiredSubstrate.weightId; }))
                        ? "".concat((_j = (_h = (_g = substrateTypes
                            .find(function (st) { return st.id === requiredSubstrate.typeId; })) === null || _g === void 0 ? void 0 : _g.substrateColours.find(function (c) { return c.id === requiredSubstrate.colourId; })) === null || _h === void 0 ? void 0 : _h.weights.find(function (w) { return w.id === requiredSubstrate.weightId; })) === null || _j === void 0 ? void 0 : _j.weight, "\n                                        ").concat((_m = (_l = (_k = substrateTypes
                            .find(function (st) { return st.id === requiredSubstrate.typeId; })) === null || _k === void 0 ? void 0 : _k.substrateColours.find(function (c) { return c.id === requiredSubstrate.colourId; })) === null || _l === void 0 ? void 0 : _l.weights.find(function (w) { return w.id === requiredSubstrate.weightId; })) === null || _m === void 0 ? void 0 : _m.weightUnit)
                        : 'Unknown Weight'),
                    React.createElement("td", null, requiredSubstrate.design),
                    React.createElement("td", { className: "text-end" },
                        React.createElement(Button, { className: "btn-sm me-2", variant: "primary", onClick: function () { return setShowModal(true); }, disabled: !isEditable }, "Edit"),
                        React.createElement(Button, { className: "btn-sm", variant: "danger", onClick: function () { return setFieldValue('requiredSubstrate', undefined); }, disabled: !isEditable }, "Remove")))))) : (React.createElement("p", null, "No required substrate selected.")),
        React.createElement("div", { className: "text-end" },
            React.createElement(Button, { variant: "secondary", onClick: function () { return setShowModal(true); }, disabled: !isEditable }, requiredSubstrate ? 'Edit Required Substrate' : 'Add Required Substrate')),
        React.createElement(Modal, { show: showModal, onHide: function () { return setShowModal(false); } },
            React.createElement(Formik, { initialValues: initialValues, enableReinitialize: true, validationSchema: validationSchema, onSubmit: handleSubmit }, function (_a) {
                var values = _a.values, handleChange = _a.handleChange, handleSubmit = _a.handleSubmit, errors = _a.errors, touched = _a.touched, setFieldValue = _a.setFieldValue, isSubmitting = _a.isSubmitting, submitCount = _a.submitCount;
                return (React.createElement(FormikForm, null,
                    React.createElement(Modal.Header, { closeButton: true },
                        React.createElement(Modal.Title, null, isEditing ? 'Edit Required Substrate' : 'Add Required Substrate')),
                    React.createElement(Modal.Body, null,
                        submitCount > 0 && Object.keys(errors).length > 0 && (React.createElement(Alert, { variant: "danger" }, Object.values(errors).map(function (err, idx) { return (React.createElement("div", { key: "required-substrate-error-".concat(idx) }, err)); }))),
                        React.createElement(Form.Group, { className: "mb-3" },
                            React.createElement(Form.Label, null, "Select Substrate Type"),
                            React.createElement(Form.Control, { as: "select", name: "substrateType", value: values.substrateType, onChange: function (e) {
                                    handleChange(e);
                                    var newSubstrateType = e.target.value;
                                    // Reset colour and weight when substrate type changes
                                    setFieldValue('colour', '');
                                    setFieldValue('weight', '');
                                }, isInvalid: touched.substrateType && !!errors.substrateType, disabled: !isEditable },
                                React.createElement("option", { value: "" }, "Select Substrate Type"),
                                substrateTypes.map(function (substrate) { return (React.createElement("option", { key: "substrate-".concat(substrate.id, "-").concat(substrate.substrateType), value: substrate.substrateType }, substrate.label)); })),
                            React.createElement(Form.Control.Feedback, { type: "invalid" }, errors.substrateType)),
                        getAvailableColours(values.substrateType).length > 0 && (React.createElement(Form.Group, { className: "mb-3" },
                            React.createElement(Form.Label, null, "Select Colour"),
                            React.createElement(Form.Control, { as: "select", name: "colour", value: values.colour, onChange: function (e) {
                                    handleChange(e);
                                    var newColour = e.target.value;
                                    // Reset weight when colour changes
                                    setFieldValue('weight', '');
                                }, isInvalid: touched.colour && !!errors.colour, disabled: !isEditable },
                                React.createElement("option", { value: "" }, "Select Colour"),
                                getAvailableColours(values.substrateType).map(function (colour) { return (React.createElement("option", { key: "substrate-colour-".concat(colour), value: colour.substrateColour }, colour.name)); })),
                            React.createElement(Form.Control.Feedback, { type: "invalid" }, errors.colour))),
                        getAvailableWeights(values.substrateType, values.colour).length > 0 && (React.createElement(Form.Group, { className: "mb-3" },
                            React.createElement(Form.Label, null, "Select Weight"),
                            React.createElement(Form.Control, { as: "select", name: "weight", value: values.weight, onChange: function (e) {
                                    handleChange(e);
                                    setFieldValue('weight', e.target.value);
                                }, isInvalid: touched.weight && !!errors.weight, disabled: !isEditable },
                                React.createElement("option", { value: "" }, "Select Weight"),
                                getAvailableWeights(values.substrateType, values.colour).map(function (weight) { return (React.createElement("option", { key: "substrate-weight-".concat(weight), value: weight.id.toString() }, "".concat(weight.weight).concat(weight.weightUnit))); })),
                            React.createElement(Form.Control.Feedback, { type: "invalid" }, errors.weight)))),
                    React.createElement(Modal.Footer, null,
                        React.createElement(Button, { variant: "secondary", onClick: function () { return setShowModal(false); } }, "Close"),
                        React.createElement(Button, { variant: "primary", type: "submit", disabled: !values.substrateType ||
                                !values.colour ||
                                !values.weight ||
                                isSubmitting }, "Save"))));
            }))));
};
export default RequiredSubstrateEditor;
