import React from 'react';
import ReactDiffViewer from 'react-diff-viewer-continued/lib/src/index.js';
import Moment from 'react-moment';
var LogContent = function (_a) {
    var element = _a.element;
    if (!element) {
        return React.createElement("div", { className: 'p-4' }, "Select Element to see the Change");
    }
    var formatJson = function (jsonString) {
        try {
            return JSON.stringify(jsonString, null, 2);
        }
        catch (e) {
            return jsonString;
        }
    };
    return (React.createElement("div", { className: "p-4" },
        React.createElement("div", { className: "d-flex justify-content-between align-items-center mb-4" },
            React.createElement("h5", { className: "fw-bold" }, element.type),
            React.createElement("div", { className: "text-muted" },
                React.createElement(Moment, { format: "LLL" }, element.dateModified))),
        React.createElement(ReactDiffViewer, { oldValue: formatJson(element.jsonObjectBefore), newValue: formatJson(element.jsonObjectAfter), splitView: false, hideLineNumbers: false, showDiffOnly: true, useDarkTheme: false, styles: {
                contentText: {
                    fontSize: '13px',
                    lineHeight: '1.5',
                    fontFamily: 'monospace'
                }
            } }),
        React.createElement("div", { className: "mt-4 pt-3 border-top" },
            React.createElement("div", { className: "text-muted small" },
                "Modified by: ", "".concat(element.userName, " (").concat(element.userId, ")")))));
};
export default LogContent;
