import defineComponent from '../../../../components/flight/lib/component';
import DataTableNet from '../../table/data-table-net/data-table-net.tsx';
import axios from "axios";

export default defineComponent(PepperPublicationsDatatable);

function PepperPublicationsDatatable() {

    this.attributes({
        url: "/api/admin/pepper/publications",
        dataTableSelector: '[data-type="data-table"]'
    });

    this.createSchema = function () {
        const cols = [];

        cols.push({
            title: '',
            type: 'thumbnail',
            data: 'front'
        });

        cols.push({
            title: 'Book Title',
            type: 'text',
            data: 'bookName'
        });

        cols.push({
            title: 'Author',
            type: 'text',
            data: 'author'
        });

        cols.push({
            title: 'Recipes',
            type: 'number',
            data: 'recipeCount'
        });

        cols.push({
            title: 'External Id',
            type: 'text',
            data: 'externalId'
        });

        cols.push({
            title: 'Modified',
            type: 'timebox',
            data: 'lastModifiedDate',
            defaultSort: true,
            sortOrder: 'desc'
        });

        cols.push({
            title: 'Error',
            type: 'text',
            data: 'error'
        });

        cols.push({
            title: '',
            type: 'multiButton',
            width: '300px',
            data: 'assetLinks'
        });

        cols.push({
            title: '',
            type: 'multiButton',
            width: '300px',
            data: 'viewLinks'
        });

        return cols;
    };

    this.paint = function () {
        const data = {
            columns: this.createSchema(),
            response: this.normalize(this.data)
        };

        if (this.dataReadyFired) {
            window.dispatchEvent(new CustomEvent('uiDataUpdate', { detail: data }));
        } else {
            this.dataReadyFired = true;
            this.trigger("uiDataReady", data);
        }
    };

    this.normalize = function (publications) {
        return publications.map(publication => this.normalizePublicationData(publication));
    };

    this.normalizePublicationData = function (publication) {
        const result = $.extend(true, {}, publication);
        result.lastModifiedDate = publication.lastModifiedDateTimestamp.timestamp;
        result.front = {
            src: publication.thumbnailUrl,
            href: `/peppertheapp/${publication.externalId}`,
            title: publication.bookName,
            target: "_blank"
        };


        result.viewLinks = [
            {
                href: `/peppertheapp/${publication.externalId}`,
                caption: 'View',
                target: '_blank',
                className: 'btn btn-sm btn-primary'
            },
        ];

        if(publication.publicationOrderId) {
            result.viewLinks.push(
                {
                    href: `/orders/${publication.publicationOrderId}`,
                    caption: 'Publication Order',
                    target: '_blank',
                    className: 'btn btn-sm btn-primary',
                }
            );
        }

        result.assetLinks = [];
        if(publication.bodyPdfUrl) {
            result.assetLinks.push(
                {
                    href: publication.bodyPdfUrl,
                    caption: 'Body PDF',
                    target: '_blank',
                    className: 'btn btn-sm btn-secondary'
                }
            );
        }

        if(publication.spinePdfUrl) {
            result.assetLinks.push(
                {
                    href: publication.spinePdfUrl,
                    caption: 'Spine PDF',
                    target: '_blank',
                    className: 'btn btn-sm btn-secondary',
                }
            );
        }



        return result;
    };

    this.getData = function () {
        axios.get(this.attr.url)
            .then(response => {
                this.data = response.data;
                this.paint();
            }
        );
    };

    this.after('initialize', function () {
        DataTableNet.attachTo(this.select('dataTableSelector'));
        setTimeout(() => this.getData(), 10);
    });
}
