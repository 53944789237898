import defineComponent from '../../../../components/flight/lib/component';
import axios from "axios";

export default defineComponent(PublishingHouseEditor);

function PublishingHouseEditor() {

    this.attributes({
        addAdditionalUserBtn: '[data-type="add-additional-user-btn"]',
        removeAdditionalUserBtn: '[data-type="remove-additional-user-btn"]',
        publishingHouseId: '',
        additionalUser: '[data-field="additional-user"]',
        additionalUsersContainer: '[data-type="additional-users"]',
    });

    this.addAdditionalUser = function() {
        const emailAddress = this.select('additionalUser').val();
        axios.post(
            `/admin/print-on-demand/publishing-house/${this.publishingHouseId}/user?emailAddress=${encodeURIComponent(emailAddress)}`
        ).then((res) => {
            console.log(res);
            this.select('additionalUsersContainer').html(res.data);
        });

    };

    this.removeAdditionalUser = function(event) {
        const userId = $(event.target).data('user-id');
        axios.delete(
            `/admin/print-on-demand/publishing-house/${this.publishingHouseId}/users/${userId}`
        ).then((res) => {
            this.select('additionalUsersContainer').html(res.data);
        });
    };

    this.after('initialize', function () {
        this.publishingHouseId = document.getElementById('publishingHouseId').value;

        this.on('click', {
            addAdditionalUserBtn: this.addAdditionalUser,
            removeAdditionalUserBtn: this.removeAdditionalUser,
        });

    });

}