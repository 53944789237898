import React, { useState } from "react";
import { useQuery } from "react-query";
import ChangeLogsApiClient from "./ChangeLogsApiClient";
import LogContent from "./LogContent";
import LogList from "./LogList";
export default function ApiChangeLogs(_a) {
    var secureDomain = _a.secureDomain, shopId = _a.shopId, logId = _a.logId, logType = _a.logType, logObjectId = _a.logObjectId;
    var _b = useState(null), selectedElement = _b[0], setSelectedElement = _b[1];
    var changeLogsService = new ChangeLogsApiClient(secureDomain);
    var _c = useQuery(['changeLogs', logObjectId, logType], function () { return logType
        ? changeLogsService.getChangeLogsByRecordIdAndLogType(logObjectId, logType)
        : changeLogsService.getChangeLogsByRecordId(logObjectId); }), changeLogs = _c.data, isLoading = _c.isLoading, error = _c.error;
    if (isLoading) {
        return React.createElement("div", null, "Loading...");
    }
    if (error) {
        return React.createElement("div", null, "Error loading change logs");
    }
    return (React.createElement("div", { className: "mt-4" },
        React.createElement("div", { className: "row" },
            React.createElement("div", { className: "col-md-4 border-light border-1 border-end border-top p-0" },
                React.createElement(LogList, { elements: changeLogs || [], onSelectElement: setSelectedElement })),
            React.createElement("div", { className: "col-md-8 border-light border-1 border-start border-top" },
                React.createElement(LogContent, { element: selectedElement })))));
}
