var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState } from 'react';
import { Form, Button, Table, Modal, Alert } from 'react-bootstrap';
import { Formik, Form as FormikForm, ErrorMessage } from 'formik';
import * as Yup from 'yup';
var BindingColourOptionsEditor = function (_a) {
    var bindingColours = _a.bindingColours, values = _a.values, setFieldValue = _a.setFieldValue, isEditable = _a.isEditable;
    var _b = useState(false), showModal = _b[0], setShowModal = _b[1];
    var _c = useState(null), editIndex = _c[0], setEditIndex = _c[1];
    var bindingColourOptions = values.bindingColourOptions || [];
    var initialModalValues = {
        bindingColour: '',
        santaDefault: false,
    };
    var validationSchema = Yup.object().shape({
        bindingColour: Yup.string().required('Binding Colour is required'),
        santaDefault: Yup.boolean(),
    });
    var handleAddOrEditColourOption = function (option) {
        var updatedOptions = __spreadArray([], bindingColourOptions, true);
        if (editIndex !== null) {
            updatedOptions[editIndex] = option;
        }
        else {
            updatedOptions.push(option);
        }
        // If santaDefault is true, unset others
        if (option.santaDefault) {
            updatedOptions.forEach(function (opt, idx) {
                if (idx !== (editIndex !== null ? editIndex : updatedOptions.length - 1)) {
                    opt.santaDefault = false;
                }
            });
        }
        setFieldValue('bindingColourOptions', updatedOptions);
        setShowModal(false);
        setEditIndex(null);
    };
    var handleEditClick = function (index) {
        setEditIndex(index);
        setShowModal(true);
    };
    var getErrorMessages = function (errors) {
        var messages = [];
        var extractErrors = function (errObj) {
            Object.values(errObj).forEach(function (value) {
                if (typeof value === 'string') {
                    messages.push(value);
                }
                else if (typeof value === 'object' && value !== null) {
                    extractErrors(value);
                }
            });
        };
        extractErrors(errors);
        return messages;
    };
    return (React.createElement("div", { className: "mb-3" },
        React.createElement("h6", null, "Binding Colour Options"),
        bindingColourOptions.length === 0 ? (React.createElement("p", null, "No binding colour options available.")) : (React.createElement(Table, { bordered: true, hover: true, variant: "light", size: "sm" },
            React.createElement("thead", null,
                React.createElement("tr", null,
                    React.createElement("th", null, "Binding Colour"),
                    React.createElement("th", null, "Default"),
                    React.createElement("th", null))),
            React.createElement("tbody", null, bindingColourOptions.map(function (option, index) { return (React.createElement("tr", { key: "binding-colour-".concat(option.bindingColour) },
                React.createElement("td", null, option.bindingColour),
                React.createElement("td", null,
                    React.createElement(Form.Check, { type: "radio", name: "bindingColourOptions.santaDefault", checked: option.santaDefault, onChange: function () {
                            var updatedOptions = __spreadArray([], bindingColourOptions, true);
                            updatedOptions.forEach(function (opt, idx) {
                                opt.santaDefault = idx === index;
                            });
                            setFieldValue('bindingColourOptions', updatedOptions);
                        }, disabled: !isEditable })),
                React.createElement("td", { className: "text-end" },
                    React.createElement(Button, { variant: "primary", size: "sm", className: "me-2", onClick: function () { return handleEditClick(index); }, disabled: !isEditable }, "Edit"),
                    React.createElement(Button, { variant: "danger", size: "sm", onClick: function () {
                            var updatedOptions = __spreadArray([], bindingColourOptions, true);
                            updatedOptions.splice(index, 1);
                            setFieldValue('bindingColourOptions', updatedOptions);
                        }, disabled: !isEditable }, "Delete")))); })))),
        React.createElement("div", { className: "text-end" },
            React.createElement(Button, { variant: "secondary", onClick: function () {
                    setEditIndex(null);
                    setShowModal(true);
                }, disabled: !isEditable }, "Add Binding Colour Option")),
        React.createElement(Modal, { show: showModal, onHide: function () {
                setShowModal(false);
                setEditIndex(null);
            } },
            React.createElement(Formik, { initialValues: editIndex !== null
                    ? __assign(__assign({}, initialModalValues), bindingColourOptions[editIndex]) : initialModalValues, enableReinitialize: true, validationSchema: validationSchema, onSubmit: function (values, _a) {
                    var setSubmitting = _a.setSubmitting;
                    handleAddOrEditColourOption(values);
                    setSubmitting(false);
                } }, function (_a) {
                var values = _a.values, handleChange = _a.handleChange, handleSubmit = _a.handleSubmit, errors = _a.errors, touched = _a.touched, setFieldValue = _a.setFieldValue, isSubmitting = _a.isSubmitting, submitCount = _a.submitCount;
                return (React.createElement(FormikForm, null,
                    React.createElement(Modal.Header, { closeButton: true },
                        React.createElement(Modal.Title, null, editIndex !== null ? 'Edit Binding Colour Option' : 'Add Binding Colour Option')),
                    React.createElement(Modal.Body, null,
                        submitCount > 0 && Object.keys(errors).length > 0 && (React.createElement(Alert, { variant: "danger" }, getErrorMessages(errors).map(function (err, idx) { return (React.createElement("div", { key: "binding-colour-error-".concat(idx) }, err)); }))),
                        React.createElement(Form.Group, { className: "mb-3" },
                            React.createElement(Form.Label, null, "Binding Colour"),
                            React.createElement(Form.Control, { as: "select", name: "bindingColour", value: values.bindingColour, onChange: handleChange, isInvalid: touched.bindingColour && !!errors.bindingColour, disabled: !isEditable },
                                React.createElement("option", { value: "" }, "Select Binding Colour"),
                                bindingColours.map(function (colour, idx) { return (React.createElement("option", { key: "binding-colour-option-".concat(colour), value: colour }, colour)); })),
                            React.createElement(ErrorMessage, { name: "bindingColour", component: "div", className: "text-danger" })),
                        React.createElement(Form.Group, { className: "mb-3" },
                            React.createElement(Form.Check, { type: "checkbox", label: "Set as Default", name: "santaDefault", checked: values.santaDefault, onChange: handleChange, disabled: !isEditable }))),
                    React.createElement(Modal.Footer, null,
                        React.createElement(Button, { variant: "secondary", onClick: function () {
                                setShowModal(false);
                                setEditIndex(null);
                            } }, "Close"),
                        React.createElement(Button, { variant: "primary", type: "submit", disabled: !isEditable || isSubmitting },
                            editIndex !== null ? 'Update' : 'Add',
                            " Binding Colour Option"))));
            }))));
};
export default BindingColourOptionsEditor;
