import React, { useState } from 'react';
import Moment from 'react-moment';
var LogList = function (_a) {
    var elements = _a.elements, onSelectElement = _a.onSelectElement;
    var _b = useState(null), selectedElementIndex = _b[0], setSelectedElementIndex = _b[1];
    if (!elements || elements.length === 0) {
        return React.createElement("div", { className: "p-4" }, "No Logs found");
    }
    var shouldShowDateDivider = function (currentDate, previousDate) {
        if (!previousDate)
            return true;
        var current = new Date(currentDate).setHours(0, 0, 0, 0);
        var previous = new Date(previousDate).setHours(0, 0, 0, 0);
        return current !== previous;
    };
    return (React.createElement("div", { className: "log-list" }, elements.map(function (element, index) { return (React.createElement(React.Fragment, { key: index },
        shouldShowDateDivider(element.dateModified, index > 0 ? elements[index - 1].dateModified : undefined) && (React.createElement("div", { className: "d-flex p-3 fw-bold ".concat(index !== 0 ? 'border-light border-1 border-top' : '') },
            React.createElement(Moment, { format: "LL" }, element.dateModified))),
        React.createElement("div", { className: "d-flex p-3 border-light border-1 border-top ".concat(index === selectedElementIndex ? 'bg-light' : ''), onClick: function () {
                onSelectElement(element);
                setSelectedElementIndex(index);
            }, style: { cursor: 'pointer' } },
            React.createElement("div", { className: "col-12" },
                React.createElement(Moment, { format: "HH:mm:ss" }, element.dateModified),
                " - ",
                React.createElement("span", { className: "text-truncate" }, element.userName))))); })));
};
export default LogList;
