var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from 'react';
import { Form as FormikForm, Formik } from 'formik';
import * as Yup from 'yup';
import { Alert, Button, Card, Col, Form, Row, Spinner, } from 'react-bootstrap';
import axios from 'axios';
import ConfirmationModal from './ConfirmationModal';
import BindingEdgesEditor from './BoundMetadata/BindingEdgesEditor';
import EndPaperOptionsEditor from './BoundMetadata/EndPaperOptionsEditor';
import RibbonOptionsEditor from './BoundMetadata/RibbonOptionsEditor';
import HeadAndTailBandOptionsEditor from './BoundMetadata/HeadAndTailBandOptionsEditor';
import BindingTypeOptionsEditor from './BoundMetadata/BindingTypeOptionsEditor';
var validationSchema = Yup.object().shape({
    defaultPages: Yup.number()
        .required('Default Pages is required')
        .min(4, 'Must be at least 4')
        .test('is-multiple-of-4', 'Default Pages must be a multiple of 4', function (value) { return value % 4 === 0; }),
    minPages: Yup.number()
        .required('Minimum Pages is required')
        .min(4, 'Must be at least 4')
        .test('is-multiple-of-4', 'Minimum Pages must be a multiple of 4', function (value) { return value % 4 === 0; }),
    maxPages: Yup.number()
        .required('Maximum Pages is required')
        .min(Yup.ref('minPages'), 'Must be greater than or equal to Minimum Pages')
        .test('is-multiple-of-4', 'Maximum Pages must be a multiple of 4', function (value) { return value % 4 === 0; }),
    pagesPerLeaf: Yup.number()
        .required('Pages Per Leaf is required')
        .min(1, 'Must be at least 1'),
    pagesIncrement: Yup.number()
        .required('Pages Increment is required')
        .min(4, 'Must be at least 4')
        .test('is-multiple-of-4', 'Pages Increment must be a multiple of 4', function (value) { return value % 4 === 0; }),
    bindingEdges: Yup.array()
        .of(Yup.object().shape({
        bindingEdge: Yup.string().required('Binding Edge is required'),
        santaDefault: Yup.boolean(),
    }))
        .min(1, 'At least one binding edge is required')
        .test('one-default', 'At least one binding edge must be set as default', function (bindingEdges) {
        return bindingEdges ? bindingEdges.some(function (edge) { return edge.santaDefault; }) : false;
    }),
    endpaperOptions: Yup.array()
        .of(Yup.object().shape({
        endPaperColour: Yup.string().required('Endpaper Colour is required'),
        colourCode: Yup.string().required('Colour Code is required'),
        santaDefault: Yup.boolean(),
    }))
        .test('one-default', 'At least one endpaper option must be set as default', function (endpaperOptions) {
        if (!endpaperOptions || endpaperOptions.length === 0) {
            return true; // No endpaperOptions provided, validation passes
        }
        return endpaperOptions.some(function (opt) { return opt.santaDefault; });
    }),
    ribbonOptions: Yup.array()
        .of(Yup.object().shape({
        ribbonColour: Yup.string().required('Ribbon Colour is required'),
        colourCode: Yup.string().required('Colour Code is required'),
        santaDefault: Yup.boolean(),
    }))
        .test('one-default', 'At least one ribbon option must be set as default', function (ribbonOptions) {
        if (!ribbonOptions || ribbonOptions.length === 0) {
            return true; // No ribbonOptions provided, validation passes
        }
        return ribbonOptions.some(function (opt) { return opt.santaDefault; });
    }),
    headAndTailBandOptions: Yup.array()
        .of(Yup.object().shape({
        headAndTailBand: Yup.string().required('Head and Tail Band is required'),
        colourCode: Yup.string().required('Colour Code is required'),
        santaDefault: Yup.boolean(),
    }))
        .test('one-default', 'At least one head and tail band option must be set as default', function (headAndTailBandOptions) {
        if (!headAndTailBandOptions || headAndTailBandOptions.length === 0) {
            return true; // No options provided, validation passes
        }
        return headAndTailBandOptions.some(function (opt) { return opt.santaDefault; });
    }),
    bindingTypeOptions: Yup.array()
        .of(Yup.object().shape({
        bindingType: Yup.string().required('Binding Type is required'),
        requiredSubstrate: Yup.object().nullable(),
        defaultPages: Yup.number()
            .required('Default Pages is required')
            .min(4, 'Must be at least 4'),
        santaDefault: Yup.boolean(),
        supportsEndPapers: Yup.boolean(),
        supportsDustJackets: Yup.boolean(),
        supportsRibbons: Yup.boolean(),
        supportsHeadAndTailBands: Yup.boolean(),
        bindingColourOptions: Yup.array()
            .of(Yup.object().shape({
            bindingColour: Yup.string().required('Binding Colour is required'),
            santaDefault: Yup.boolean(),
        }))
            .nullable() // Allow null or empty array
            .test('one-default', 'At least one binding colour option must be set as default', function (bindingColourOptions) {
            if (!bindingColourOptions || bindingColourOptions.length === 0) {
                return true; // No options provided, validation passes
            }
            return bindingColourOptions.some(function (opt) { return opt.santaDefault; });
        }),
        sewing: Yup.string().required('Sewing is required'),
        preDrilledHoles: Yup.string().required('Pre-drilled Holes is required'),
        separateCover: Yup.string().required('Separate Cover is required'),
    }))
        .test('one-default', 'At least one binding type option must be set as default', function (bindingTypeOptions) {
        if (!bindingTypeOptions || bindingTypeOptions.length === 0) {
            return true; // No options provided, validation passes
        }
        return bindingTypeOptions.some(function (opt) { return opt.santaDefault; });
    }),
})
    .test('endpapers-required', 'At least one endpaper option must be configured when any binding type supports endpapers.', function (value) {
    var bindingTypeOptions = value.bindingTypeOptions, endpaperOptions = value.endpaperOptions;
    var supportsEndPapers = bindingTypeOptions === null || bindingTypeOptions === void 0 ? void 0 : bindingTypeOptions.some(function (opt) { return opt.supportsEndPapers; });
    if (supportsEndPapers) {
        return endpaperOptions && endpaperOptions.length > 0;
    }
    return true;
})
    .test('ribbons-required', 'At least one ribbon option must be configured when any binding type supports ribbons.', function (value) {
    var bindingTypeOptions = value.bindingTypeOptions, ribbonOptions = value.ribbonOptions;
    var supportsRibbons = bindingTypeOptions === null || bindingTypeOptions === void 0 ? void 0 : bindingTypeOptions.some(function (opt) { return opt.supportsRibbons; });
    if (supportsRibbons) {
        return ribbonOptions && ribbonOptions.length > 0;
    }
    return true;
})
    .test('head-and-tail-bands-required', 'At least one head and tail band option must be configured when any binding type supports head and tail bands.', function (value) {
    var bindingTypeOptions = value.bindingTypeOptions, headAndTailBandOptions = value.headAndTailBandOptions;
    var supportsHeadAndTailBands = bindingTypeOptions === null || bindingTypeOptions === void 0 ? void 0 : bindingTypeOptions.some(function (opt) { return opt.supportsHeadAndTailBands; });
    if (supportsHeadAndTailBands) {
        return headAndTailBandOptions && headAndTailBandOptions.length > 0;
    }
    return true;
});
var BoundMetaDataEditor = function (_a) {
    var _b, _c, _d, _e, _f;
    var productId = _a.productId, subProductId = _a.subProductId, santaType = _a.santaType, bindingColours = _a.bindingColours, ribbonColours = _a.ribbonColours, endPaperColours = _a.endPaperColours, headAndTailBands = _a.headAndTailBands, substrateTypes = _a.substrateTypes;
    var _g = useState(true), isLoading = _g[0], setLoading = _g[1];
    var _h = useState(null), error = _h[0], setError = _h[1];
    var _j = useState(null), successMessage = _j[0], setSuccessMessage = _j[1];
    var _k = useState(null), metadataDocumentWrapper = _k[0], setMetadataDocumentWrapper = _k[1];
    var _l = useState(false), useCustom = _l[0], setUseCustom = _l[1];
    var _m = useState(false), showConfirmationModal = _m[0], setShowConfirmationModal = _m[1];
    useEffect(function () {
        var fetchMetadata = function () { return __awaiter(void 0, void 0, void 0, function () {
            var response, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setLoading(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, axios.get("/api/admin/metadata/product/bound/products/".concat(productId, "/sub-products/").concat(subProductId, "/santa-types/").concat(santaType))];
                    case 2:
                        response = _a.sent();
                        setMetadataDocumentWrapper(response.data);
                        if (subProductId !== 0) {
                            setUseCustom(!!response.data.productMetadataDocument);
                        }
                        return [3 /*break*/, 5];
                    case 3:
                        err_1 = _a.sent();
                        setError('Failed to load bound metadata document.');
                        return [3 /*break*/, 5];
                    case 4:
                        setLoading(false);
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        fetchMetadata();
    }, [productId, subProductId, santaType]);
    var handleSave = function (values, _a) {
        var setSubmitting = _a.setSubmitting;
        return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!useCustom && subProductId !== 0) {
                            setShowConfirmationModal(true);
                            setSubmitting(false);
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, saveBoundMetadata(values, setSubmitting)];
                    case 1:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    var saveBoundMetadata = function (values, setSubmitting) { return __awaiter(void 0, void 0, void 0, function () {
        var activeDocument_1, newDocument, updatedDocument, saveRequest, response, error_1;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    setLoading(true);
                    setError(null);
                    setSuccessMessage(null);
                    _c.label = 1;
                case 1:
                    _c.trys.push([1, 3, 4, 5]);
                    activeDocument_1 = useCustom
                        ? metadataDocumentWrapper === null || metadataDocumentWrapper === void 0 ? void 0 : metadataDocumentWrapper.productMetadataDocument
                        : metadataDocumentWrapper === null || metadataDocumentWrapper === void 0 ? void 0 : metadataDocumentWrapper.defaultProductMetadataDocument;
                    newDocument = activeDocument_1
                        ? __assign({}, activeDocument_1) : {
                        shopId: (_a = metadataDocumentWrapper === null || metadataDocumentWrapper === void 0 ? void 0 : metadataDocumentWrapper.defaultProductMetadataDocument) === null || _a === void 0 ? void 0 : _a.shopId,
                        productId: productId,
                        subProductId: subProductId,
                        santaType: santaType,
                    };
                    updatedDocument = __assign(__assign({}, newDocument), values);
                    saveRequest = {
                        useDefault: !useCustom,
                        boundMetadataDocument: updatedDocument,
                    };
                    return [4 /*yield*/, axios.post("/api/admin/metadata/product/bound/products/".concat(productId, "/sub-products/").concat(subProductId, "/santa-types/").concat(santaType), saveRequest)];
                case 2:
                    response = _c.sent();
                    setMetadataDocumentWrapper(response.data);
                    setSuccessMessage('Bound Metadata saved successfully!');
                    return [3 /*break*/, 5];
                case 3:
                    error_1 = _c.sent();
                    setError("Failed to save bound metadata: ".concat(((_b = error_1.response) === null || _b === void 0 ? void 0 : _b.data) || error_1.message));
                    return [3 /*break*/, 5];
                case 4:
                    setLoading(false);
                    setSubmitting(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var handleCopyFromDefault = function () {
        var _a, _b, _c, _d, _e;
        if (metadataDocumentWrapper === null || metadataDocumentWrapper === void 0 ? void 0 : metadataDocumentWrapper.defaultProductMetadataDocument) {
            var defaultData = metadataDocumentWrapper.defaultProductMetadataDocument;
            var updatedCustomDocument = {
                shopId: defaultData.shopId,
                productId: defaultData.productId,
                subProductId: subProductId,
                santaType: defaultData.santaType,
                defaultPages: defaultData.defaultPages,
                minPages: defaultData.minPages,
                maxPages: defaultData.maxPages,
                pagesPerLeaf: defaultData.pagesPerLeaf,
                pagesIncrement: defaultData.pagesIncrement,
                bindingEdges: ((_a = defaultData.bindingEdges) === null || _a === void 0 ? void 0 : _a.map(function (edge) { return ({
                    bindingEdge: edge.bindingEdge || edge,
                    santaDefault: edge.santaDefault || false,
                }); })) || [],
                endpaperOptions: ((_b = defaultData.endpaperOptions) === null || _b === void 0 ? void 0 : _b.map(function (option) { return ({
                    endPaperColour: option.endPaperColour || option,
                    colourCode: option.colourCode || '',
                    santaDefault: option.santaDefault || false,
                }); })) || [],
                ribbonOptions: ((_c = defaultData.ribbonOptions) === null || _c === void 0 ? void 0 : _c.map(function (option) { return ({
                    ribbonColour: option.ribbonColour || option,
                    colourCode: option.colourCode || '',
                    santaDefault: option.santaDefault || false,
                }); })) || [],
                headAndTailBandOptions: ((_d = defaultData.headAndTailBandOptions) === null || _d === void 0 ? void 0 : _d.map(function (option) { return ({
                    headAndTailBand: option.headAndTailBand || option,
                    colourCode: option.colourCode || '',
                    santaDefault: option.santaDefault || false,
                }); })) || [],
                bindingTypeOptions: ((_e = defaultData.bindingTypeOptions) === null || _e === void 0 ? void 0 : _e.map(function (option) {
                    var _a;
                    return (__assign(__assign({}, option), { bindingColourOptions: ((_a = option.bindingColourOptions) === null || _a === void 0 ? void 0 : _a.map(function (colourOption) { return (__assign({}, colourOption)); })) || [], requiredSubstrate: option.requiredSubstrate || null }));
                })) || [],
            };
            setMetadataDocumentWrapper(__assign(__assign({}, metadataDocumentWrapper), { productMetadataDocument: updatedCustomDocument }));
        }
    };
    var isEditable = useCustom || subProductId === 0;
    if (isLoading) {
        return React.createElement(Spinner, { animation: "grow", variant: "primary" });
    }
    if (!metadataDocumentWrapper) {
        return React.createElement(Alert, { variant: "danger" }, "No Bound Metadata available");
    }
    var activeDocument = useCustom
        ? metadataDocumentWrapper === null || metadataDocumentWrapper === void 0 ? void 0 : metadataDocumentWrapper.productMetadataDocument
        : metadataDocumentWrapper === null || metadataDocumentWrapper === void 0 ? void 0 : metadataDocumentWrapper.defaultProductMetadataDocument;
    var initialValues = {
        defaultPages: (activeDocument === null || activeDocument === void 0 ? void 0 : activeDocument.defaultPages) || 8,
        minPages: (activeDocument === null || activeDocument === void 0 ? void 0 : activeDocument.minPages) || 4,
        maxPages: (activeDocument === null || activeDocument === void 0 ? void 0 : activeDocument.maxPages) || 2000,
        pagesPerLeaf: (activeDocument === null || activeDocument === void 0 ? void 0 : activeDocument.pagesPerLeaf) || 2,
        pagesIncrement: (activeDocument === null || activeDocument === void 0 ? void 0 : activeDocument.pagesIncrement) || 4,
        bindingEdges: ((_b = activeDocument === null || activeDocument === void 0 ? void 0 : activeDocument.bindingEdges) === null || _b === void 0 ? void 0 : _b.map(function (edge) { return ({
            bindingEdge: edge.bindingEdge || edge,
            santaDefault: edge.santaDefault || false,
        }); })) || [],
        endpaperOptions: ((_c = activeDocument === null || activeDocument === void 0 ? void 0 : activeDocument.endpaperOptions) === null || _c === void 0 ? void 0 : _c.map(function (option) { return ({
            endPaperColour: option.endPaperColour || option,
            colourCode: option.colourCode || '',
            santaDefault: option.santaDefault || false,
        }); })) || [],
        ribbonOptions: ((_d = activeDocument === null || activeDocument === void 0 ? void 0 : activeDocument.ribbonOptions) === null || _d === void 0 ? void 0 : _d.map(function (option) { return ({
            ribbonColour: option.ribbonColour || option,
            colourCode: option.colourCode || '',
            santaDefault: option.santaDefault || false,
        }); })) || [],
        headAndTailBandOptions: ((_e = activeDocument === null || activeDocument === void 0 ? void 0 : activeDocument.headAndTailBandOptions) === null || _e === void 0 ? void 0 : _e.map(function (option) { return ({
            headAndTailBand: option.headAndTailBand || option,
            colourCode: option.colourCode || '',
            santaDefault: option.santaDefault || false,
        }); })) || [],
        bindingTypeOptions: ((_f = activeDocument === null || activeDocument === void 0 ? void 0 : activeDocument.bindingTypeOptions) === null || _f === void 0 ? void 0 : _f.map(function (option) {
            var _a;
            return (__assign(__assign({}, option), { bindingColourOptions: ((_a = option.bindingColourOptions) === null || _a === void 0 ? void 0 : _a.map(function (colourOption) { return (__assign({}, colourOption)); })) || [], requiredSubstrate: option.requiredSubstrate || null }));
        })) || [],
    };
    var getErrorMessages = function (errors) {
        var messages = [];
        var extractErrors = function (errObj) {
            Object.values(errObj).forEach(function (value) {
                if (typeof value === 'string') {
                    messages.push(value);
                }
                else if (typeof value === 'object') {
                    extractErrors(value);
                }
            });
        };
        extractErrors(errors);
        return messages;
    };
    return (React.createElement(Formik, { initialValues: initialValues, enableReinitialize: true, onSubmit: handleSave, validationSchema: validationSchema }, function (_a) {
        var isSubmitting = _a.isSubmitting, values = _a.values, setFieldValue = _a.setFieldValue, setSubmitting = _a.setSubmitting, errors = _a.errors, touched = _a.touched, submitCount = _a.submitCount;
        var hasEndpapers = values.bindingTypeOptions &&
            values.bindingTypeOptions.length > 0 &&
            values.bindingTypeOptions.some(function (bindingTypeOption) { return bindingTypeOption.supportsEndPapers; });
        var hasRibbons = values.bindingTypeOptions &&
            values.bindingTypeOptions.length > 0 &&
            values.bindingTypeOptions.some(function (bindingTypeOption) { return bindingTypeOption.supportsRibbons; });
        var hasHeadAndTailBands = values.bindingTypeOptions &&
            values.bindingTypeOptions.length > 0 &&
            values.bindingTypeOptions.some(function (bindingTypeOption) { return bindingTypeOption.supportsHeadAndTailBands; });
        return (React.createElement(React.Fragment, null,
            React.createElement(Card, { className: "p-4 shadow-sm bg-light" },
                React.createElement(FormikForm, null,
                    React.createElement("h5", { className: "mb-4" }, "Bound Metadata"),
                    successMessage && (React.createElement(Alert, { variant: "success", onClose: function () { return setSuccessMessage(null); }, dismissible: true }, successMessage)),
                    error && (React.createElement(Alert, { variant: "danger", onClose: function () { return setError(null); }, dismissible: true }, error)),
                    submitCount > 0 && Object.keys(errors).length > 0 && (React.createElement(Alert, { variant: "danger" }, getErrorMessages(errors).map(function (err, idx) { return (React.createElement("div", { key: idx }, err)); }))),
                    subProductId !== 0 && (React.createElement(Form.Group, { className: "mb-4" },
                        React.createElement(Form.Check, { type: "radio", label: "Use Default Metadata", checked: !useCustom, onChange: function () { return setUseCustom(false); } }),
                        React.createElement(Form.Check, { type: "radio", label: "Customize Metadata", checked: useCustom, onChange: function () { return setUseCustom(true); } }),
                        useCustom && (React.createElement(Button, { className: "mt-2 btn-sm", variant: "secondary", onClick: handleCopyFromDefault }, "Copy Default Values")))),
                    React.createElement(Row, null,
                        React.createElement(Col, { md: 6 },
                            React.createElement(Form.Group, { className: "mb-3" },
                                React.createElement(Form.Label, null, "Default Pages"),
                                React.createElement(Form.Control, { type: "number", name: "defaultPages", value: values.defaultPages, onChange: function (e) {
                                        return setFieldValue('defaultPages', Number(e.target.value));
                                    }, isInvalid: touched.defaultPages &&
                                        !!errors.defaultPages, disabled: !isEditable, min: 4, step: 4, inputMode: "numeric", pattern: "[0-9]*" }),
                                React.createElement(Form.Control.Feedback, { type: "invalid" }, errors.defaultPages))),
                        React.createElement(Col, { md: 6 },
                            React.createElement(Form.Group, { className: "mb-3" },
                                React.createElement(Form.Label, null, "Minimum Pages"),
                                React.createElement(Form.Control, { type: "number", name: "minPages", value: values.minPages, onChange: function (e) {
                                        return setFieldValue('minPages', Number(e.target.value));
                                    }, isInvalid: touched.minPages && !!errors.minPages, disabled: !isEditable, min: 4, step: 4, inputMode: "numeric", pattern: "[0-9]*" }),
                                React.createElement(Form.Control.Feedback, { type: "invalid" }, errors.minPages)))),
                    React.createElement(Row, null,
                        React.createElement(Col, { md: 6 },
                            React.createElement(Form.Group, { className: "mb-3" },
                                React.createElement(Form.Label, null, "Maximum Pages"),
                                React.createElement(Form.Control, { type: "number", name: "maxPages", value: values.maxPages, onChange: function (e) {
                                        return setFieldValue('maxPages', Number(e.target.value));
                                    }, isInvalid: touched.maxPages && !!errors.maxPages, disabled: !isEditable, min: values.minPages || 4, step: 4, inputMode: "numeric", pattern: "[0-9]*" }),
                                React.createElement(Form.Control.Feedback, { type: "invalid" }, errors.maxPages))),
                        React.createElement(Col, { md: 6 },
                            React.createElement(Form.Group, { className: "mb-3" },
                                React.createElement(Form.Label, null, "Pages Per Leaf"),
                                React.createElement(Form.Control, { type: "number", name: "pagesPerLeaf", value: values.pagesPerLeaf, onChange: function (e) {
                                        return setFieldValue('pagesPerLeaf', Number(e.target.value));
                                    }, isInvalid: touched.pagesPerLeaf &&
                                        !!errors.pagesPerLeaf, disabled: !isEditable, min: 1, inputMode: "numeric", pattern: "[0-9]*" }),
                                React.createElement(Form.Control.Feedback, { type: "invalid" }, errors.pagesPerLeaf)))),
                    React.createElement(Row, null,
                        React.createElement(Col, { md: 6 },
                            React.createElement(Form.Group, { className: "mb-3" },
                                React.createElement(Form.Label, null, "Pages Increment"),
                                React.createElement(Form.Control, { type: "number", name: "pagesIncrement", value: values.pagesIncrement, onChange: function (e) {
                                        return setFieldValue('pagesIncrement', Number(e.target.value));
                                    }, isInvalid: touched.pagesIncrement &&
                                        !!errors.pagesIncrement, disabled: !isEditable, min: 4, step: 4, inputMode: "numeric", pattern: "[0-9]*" }),
                                React.createElement(Form.Control.Feedback, { type: "invalid" }, errors.pagesIncrement))),
                        React.createElement(Col, { md: 6 })),
                    React.createElement(BindingEdgesEditor, { isEditable: isEditable }),
                    React.createElement(BindingTypeOptionsEditor, { isEditable: isEditable, bindingColours: bindingColours, substrateTypes: substrateTypes }),
                    hasEndpapers && (React.createElement(EndPaperOptionsEditor, { isEditable: isEditable, endPaperColours: endPaperColours })),
                    hasRibbons && (React.createElement(RibbonOptionsEditor, { isEditable: isEditable, ribbonColours: ribbonColours })),
                    hasHeadAndTailBands && (React.createElement(HeadAndTailBandOptionsEditor, { isEditable: isEditable, headAndTailBands: headAndTailBands })),
                    React.createElement(Button, { type: "submit", disabled: isSubmitting, variant: "primary", className: "mt-4" }, "Save Changes"))),
            React.createElement(ConfirmationModal, { show: showConfirmationModal, onHide: function () { return setShowConfirmationModal(false); }, onConfirm: function () {
                    setShowConfirmationModal(false);
                    saveBoundMetadata(initialValues, function () { });
                } })));
    }));
};
export default BoundMetaDataEditor;
