import defineComponent from '../../../components/flight/lib/component';
import mixam from "../../boot/mixam";
import axios from "axios";

export default defineComponent(ResellerConfig);

function ResellerConfig() {

    this.attributes({
        pageOptionSelector: '[name="productPage"]',
        resellerPreviewSelector: '[data-type="reseller-preview"]',
        widgetCodeSelector: '[data-type="widget-code"]',
        publisherShareSelector: '[data-type="publisher-share"]',
        publisherShareLabelSelector: '[data-type="publisher-share-label"]',
        primaryColourSelector: '[data-type="primary-colour"]',
        primaryHoverColourSelector: '[data-type="primary-hover-colour"]',
        primaryFocusColourSelector: '[data-type="primary-focus-colour"]',
        secondaryDarkColourSelector: '[data-type="secondary-dark-colour"]',
        secondaryBadgeColourSelector: '[data-type="secondary-badge-colour"]',
        colourSelectors: 'input[type="color"]',
        updateConfigSelector: '[data-type="update-config"]'
    });

    this.getTheme = function() {
        return {
            primary: this.select('primaryColourSelector').val(),
            primaryHover: this.select('primaryHoverColourSelector').val(),
            primaryFocus: this.select('primaryFocusColourSelector').val(),
            secondaryDark: this.select('secondaryDarkColourSelector').val(),
            secondaryBadge: this.select('secondaryBadgeColourSelector').val()
        };
    };

    this.updatePriceCalculator = function() {
        const $pageOption = this.select('pageOptionSelector').filter(':checked');
        const $resellerPreview = this.select('resellerPreviewSelector');
        if ($resellerPreview.length !== 0) {
            const html = `
                    <price-calculator
                            data-type="price-calculator"
                            secureDomain="${mixam.secureDomain}"
                            locale="${mixam.shop.locale.replace('_', '-')}"
                            theme='${JSON.stringify(this.getTheme())}'
                            data='${JSON.stringify($pageOption.data('json'))}'
                            timezone="${mixam.shop.timeZone}"
                            redirectToCart="${mixam.shop.redirectToCart}"
                        >
                        <div class="text-center">
                            <div>Loading Price Calculator</div>
                            <div class="h1">
                                <i class="fa fa-spin fa-spinner"></i>
                            </div>
                        </div>
                    </price-calculator>
                `;
            $resellerPreview.html(html);
            const code = `
&lt;div data-type="price-calculator">&lt;/div>
&lt;script>
    window.juno_ = window.juno_ || [];

    juno_.push({
        publisher: '${this.publisherId}',
        mode: 'fully fledged',
        placement: 'page top',
        container: '[data-type="price-calculator"]',
        components: [ '${$pageOption.data('uri')}' ]
    });

    !function (e, f, u, i) {
        i = 'juno_loader';
        if (!document.getElementById(i)) {
            juno_.uri = u;
            e.async = 1;
            e.id = i;
            e.src = 'https://d1e8vjamx1ssze.cloudfront.net/juno/kiosk.js';
            f.parentNode.insertBefore(e, f);
        }
    } (document.createElement('script'), document.getElementsByTagName('script')[0], '${mixam.shop.url}');
&lt;/script>
`;
            this.select('widgetCodeSelector').html(code);
        }
    };

    this.updateConfig = function() {
        const config = {
            publisherShare: Number(this.select('publisherShareSelector').val()),
            theme: this.getTheme()
        };
        axios.patch(
            `/kiosk/config`,
            config
        ).then(() => {
            this.trigger('showFixedMessage', {
                type: 'success',
                body: "Your kiosk configuration was saved.",
                interval: 15000
            });
        }).catch((error) => {
            const message = error.response
                ? error.response.statusText
                : '';
            this.trigger('showFixedMessage', {
                type: 'error',
                body: `There was a server error when saving your kiosk configuration${message && ': ' + message}`,
                interval: 15000
            });
        });
    };

    this.changePublisherShare = function() {
        const percent = Number(this.select('publisherShareSelector').val()) / 20;
        this.select('publisherShareLabelSelector').html(`${percent}%`);
    };

    this.after('initialize', function() {
        this.publisherId = $(this.node).data('publisher');
        this.on('click', {
            updateConfigSelector: this.updateConfig
        });
        this.changePublisherShare();
        this.select('pageOptionSelector').first().prop('checked', true);
        this.updatePriceCalculator();
        this.on('change', {
            publisherShareSelector: this.changePublisherShare,
            colourSelectors: this.updatePriceCalculator,
            pageOptionSelector: this.updatePriceCalculator
        });
    });

}
