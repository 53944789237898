var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState, useRef } from 'react';
import { useFormikContext } from 'formik';
import { Form, Button, Table, Modal } from 'react-bootstrap';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
var ItemTypes = {
    ROW: 'row',
};
var RibbonOptionsEditor = function (_a) {
    var isEditable = _a.isEditable, ribbonColours = _a.ribbonColours;
    var _b = useFormikContext(), values = _b.values, setFieldValue = _b.setFieldValue;
    var _c = useState(false), showModal = _c[0], setShowModal = _c[1];
    var _d = useState({
        ribbonColour: '',
        colourCode: '',
        santaDefault: false,
    }), newOption = _d[0], setNewOption = _d[1];
    var _e = useState({}), errors = _e[0], setErrors = _e[1];
    var options = values.ribbonOptions || [];
    var moveRow = function (dragIndex, hoverIndex) {
        var updatedOptions = __spreadArray([], options, true);
        var removed = updatedOptions.splice(dragIndex, 1)[0];
        updatedOptions.splice(hoverIndex, 0, removed);
        setFieldValue('ribbonOptions', updatedOptions);
    };
    var validateNewOption = function () {
        var newErrors = {};
        if (!newOption.ribbonColour) {
            newErrors.ribbonColour = 'Ribbon Colour is required';
        }
        if (!newOption.colourCode) {
            newErrors.colourCode = 'Colour Code is required';
        }
        return newErrors;
    };
    var handleAddOption = function () {
        var validationErrors = validateNewOption();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
        var updatedOptions = __spreadArray([], options, true);
        // If santaDefault is true, unset other options
        if (newOption.santaDefault) {
            updatedOptions.forEach(function (opt) {
                opt.santaDefault = false;
            });
        }
        updatedOptions.push(newOption);
        setFieldValue('ribbonOptions', updatedOptions);
        setShowModal(false);
        setNewOption({ ribbonColour: '', colourCode: '', santaDefault: false });
        setErrors({});
    };
    return (React.createElement("div", { className: "mb-4" },
        React.createElement("h5", null, "Ribbon Options"),
        React.createElement(DndProvider, { backend: HTML5Backend },
            React.createElement(Table, { bordered: true, hover: true, variant: "light" },
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("th", null, "Ribbon Colour"),
                        React.createElement("th", null, "Colour Code"),
                        React.createElement("th", null, "Default"),
                        React.createElement("th", null, "Actions"))),
                React.createElement("tbody", null, options.length > 0 ? (options.map(function (option, index) { return (React.createElement(DraggableRow, { key: "ribbon-option-".concat(option.ribbonColour, "-").concat(option.colourCode, "-").concat(option.santaDefault), index: index, option: option, moveRow: moveRow, setFieldValue: setFieldValue, options: options, isEditable: isEditable })); })) : (React.createElement("tr", null,
                    React.createElement("td", { colSpan: 4, className: "text-center" }, "No ribbon options available.")))))),
        React.createElement("div", { className: "text-end" },
            React.createElement(Button, { variant: "secondary", onClick: function () {
                    setNewOption({ ribbonColour: '', colourCode: '', santaDefault: false });
                    setErrors({});
                    setShowModal(true);
                }, disabled: !isEditable }, "Add Ribbon Option")),
        React.createElement(Modal, { show: showModal, onHide: function () { return setShowModal(false); } },
            React.createElement(Modal.Header, { closeButton: true },
                React.createElement(Modal.Title, null, "Add New Ribbon Option")),
            React.createElement(Modal.Body, null,
                React.createElement(Form, null,
                    React.createElement(Form.Group, { className: "mb-3" },
                        React.createElement(Form.Label, null, "Ribbon Colour"),
                        React.createElement(Form.Control, { as: "select", value: newOption.ribbonColour, onChange: function (e) {
                                var selectedColour = e.target.value;
                                setNewOption(__assign(__assign({}, newOption), { ribbonColour: selectedColour }));
                                setErrors(__assign(__assign({}, errors), { ribbonColour: '' }));
                            }, isInvalid: !!errors.ribbonColour },
                            React.createElement("option", { value: "" }, "Select Ribbon Colour"),
                            ribbonColours.map(function (colour) { return (React.createElement("option", { key: "ribbon-colour-".concat(colour), value: colour, disabled: options.some(function (opt) { return opt.ribbonColour === colour; }) }, colour)); })),
                        React.createElement(Form.Control.Feedback, { type: "invalid" }, errors.ribbonColour)),
                    React.createElement(Form.Group, { className: "mb-3" },
                        React.createElement(Form.Label, null, "Colour Code"),
                        React.createElement(Form.Control, { type: "text", value: newOption.colourCode, onChange: function (e) {
                                setNewOption(__assign(__assign({}, newOption), { colourCode: e.target.value }));
                                setErrors(__assign(__assign({}, errors), { colourCode: '' }));
                            }, isInvalid: !!errors.colourCode }),
                        React.createElement(Form.Control.Feedback, { type: "invalid" }, errors.colourCode)),
                    React.createElement(Form.Group, { className: "mb-3" },
                        React.createElement(Form.Check, { type: "checkbox", label: "Set as Default", checked: newOption.santaDefault, onChange: function (e) {
                                return setNewOption(__assign(__assign({}, newOption), { santaDefault: e.target.checked }));
                            } })))),
            React.createElement(Modal.Footer, null,
                React.createElement(Button, { variant: "secondary", onClick: function () { return setShowModal(false); } }, "Close"),
                React.createElement(Button, { variant: "primary", onClick: handleAddOption, disabled: !isEditable }, "Add Ribbon Option")))));
};
export default RibbonOptionsEditor;
/**
 * Draggable Row Component
 */
var DraggableRow = function (_a) {
    var index = _a.index, option = _a.option, moveRow = _a.moveRow, setFieldValue = _a.setFieldValue, options = _a.options, isEditable = _a.isEditable;
    var ref = useRef(null);
    var _b = useDrop({
        accept: ItemTypes.ROW,
        hover: function (item, monitor) {
            if (!ref.current)
                return;
            var dragIndex = item.index;
            var hoverIndex = index;
            if (dragIndex === hoverIndex)
                return;
            var hoverBoundingRect = ref.current.getBoundingClientRect();
            var hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            var clientOffset = monitor.getClientOffset();
            if (!clientOffset)
                return;
            var hoverClientY = clientOffset.y - hoverBoundingRect.top;
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY)
                return;
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY)
                return;
            moveRow(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    }), drop = _b[1];
    var _c = useDrag({
        type: ItemTypes.ROW,
        item: { index: index },
        collect: function (monitor) { return ({
            isDragging: monitor.isDragging(),
        }); },
    }), isDragging = _c[0].isDragging, drag = _c[1];
    drag(drop(ref));
    return (React.createElement("tr", { ref: ref, style: { opacity: isDragging ? 0 : 1 } },
        React.createElement("td", null, option.ribbonColour),
        React.createElement("td", null, option.colourCode),
        React.createElement("td", null,
            React.createElement(Form.Check, { type: "radio", name: "ribbonOptions.santaDefault", checked: option.santaDefault, onChange: function () {
                    var updatedOptions = options.map(function (opt, idx) { return (__assign(__assign({}, opt), { santaDefault: idx === index })); });
                    setFieldValue('ribbonOptions', updatedOptions);
                }, disabled: !isEditable })),
        React.createElement("td", { className: "text-end" },
            React.createElement(Button, { className: "btn-sm", variant: "danger", onClick: function () {
                    var updatedOptions = __spreadArray([], options, true);
                    updatedOptions.splice(index, 1);
                    setFieldValue('ribbonOptions', updatedOptions);
                }, disabled: !isEditable }, "Delete"))));
};
