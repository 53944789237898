var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Form, Button } from 'react-bootstrap';
var ItemTypes = {
    ROW: 'row',
};
var DraggableRow = function (_a) {
    var index = _a.index, option = _a.option, moveRow = _a.moveRow, setFieldValue = _a.setFieldValue, options = _a.options, isEditable = _a.isEditable, onEdit = _a.onEdit;
    var ref = useRef(null);
    var _b = useDrop({
        accept: ItemTypes.ROW,
        hover: function (item, monitor) {
            if (!ref.current)
                return;
            var dragIndex = item.index;
            var hoverIndex = index;
            if (dragIndex === hoverIndex)
                return;
            var hoverBoundingRect = ref.current.getBoundingClientRect();
            var hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            var clientOffset = monitor.getClientOffset();
            if (!clientOffset)
                return;
            var hoverClientY = clientOffset.y - hoverBoundingRect.top;
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY)
                return;
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY)
                return;
            moveRow(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    }), drop = _b[1];
    var _c = useDrag({
        type: ItemTypes.ROW,
        item: { index: index },
        collect: function (monitor) { return ({
            isDragging: monitor.isDragging(),
        }); },
    }), isDragging = _c[0].isDragging, drag = _c[1];
    drag(drop(ref));
    return (React.createElement("tr", { ref: ref, style: { opacity: isDragging ? 0 : 1 } },
        React.createElement("td", null, option.bindingType),
        React.createElement("td", null, option.sewing),
        React.createElement("td", null, option.preDrilledHoles),
        React.createElement("td", null, option.separateCover),
        React.createElement("td", null,
            React.createElement(Form.Check, { type: "radio", name: "bindingTypeOptions.santaDefault", checked: option.santaDefault, onChange: function () {
                    var updatedOptions = options.map(function (opt, idx) { return (__assign(__assign({}, opt), { santaDefault: idx === index })); });
                    setFieldValue('bindingTypeOptions', updatedOptions);
                }, disabled: !isEditable })),
        React.createElement("td", { className: "text-end" },
            React.createElement(Button, { className: "btn-sm me-2", variant: "primary", onClick: function () { return onEdit(index); }, disabled: !isEditable }, "Edit"),
            React.createElement(Button, { className: "btn-sm", variant: "danger", onClick: function () {
                    var updatedOptions = __spreadArray([], options, true);
                    updatedOptions.splice(index, 1);
                    setFieldValue('bindingTypeOptions', updatedOptions);
                }, disabled: !isEditable }, "Delete"))));
};
export default DraggableRow;
