var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import '@react-awesome-query-builder/bootstrap/css/styles.css';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Formik } from 'formik';
import { Button, FormControl } from "../../../../react/self-publishing/PublicationSettings/components/styled";
import * as yup from "yup";
import { DiscountCodeApplicationType, DiscountCodeType } from "../types";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import Alert from "react-bootstrap/Alert";
import moment from 'moment';
import DiscountCodeRuleEditor from "./DiscountCodeRuleEditor";
var schema = yup.object().shape({
    name: yup.string().required('Name is required'),
    discountCode: yup.string().required('Discount code is required'),
    enabled: yup.boolean().required(),
    validFromDate: yup
        .date()
        .required('Valid from date is required')
        .test('validFromDate', 'Valid from date must be before valid to date', function (validFromDate) {
        var validToDate = this.parent.validToDate;
        return validFromDate <= validToDate;
    }),
    validToDate: yup
        .date()
        .required('Valid to date is required')
        .test('validToDate', 'Valid to date must be after valid from date', function (validToDate) {
        var validFromDate = this.parent.validFromDate;
        return validToDate >= validFromDate;
    }),
    discountCodeType: yup.string().required('Discount type is required'),
    discountAmount: yup.number().when('discountCodeType', {
        is: function (discountCodeType) { return discountCodeType !== DiscountCodeType.FREE_DELIVERY; },
        then: function (schema) { return schema.required('Discount amount is required')
            .moreThan(0, 'Discount amount must be greater than 0'); }
    }),
    autoApply: yup.boolean().required('Auto apply is required'),
    firstOrderOnly: yup.boolean().required('First order only is required'),
    combinable: yup.boolean().required('Combinable is required'),
    discountCodeApplicationType: yup.string().required('Applies to is required'),
    usesPerCustomer: yup.number().when('autoApply', {
        is: false,
        then: function (schema) { return schema.required('Uses per customer is required')
            .moreThan(0, 'Uses per customer must be greater than 0'); }
    }),
});
var discountCodesUrl = "/admin/finance/discount-codes";
export var DiscountCodeForm = function (props) {
    var _a = useState(props.discountCode), discountCode = _a[0], setDiscountCode = _a[1];
    var _b = useState(undefined), errorMessage = _b[0], setErrorMessage = _b[1];
    var _c = useState(false), shouldSubmitForm = _c[0], setShouldSubmitForm = _c[1];
    var discountCodeRuleEditorRef = useRef(null);
    var renderDiscountCodeForm = function () { return (React.createElement(React.Fragment, null,
        React.createElement(Formik, { validationSchema: schema, onSubmit: function (values) { return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!shouldSubmitForm) return [3 /*break*/, 4];
                            discountCode.name = values.name;
                            discountCode.enabled = values.enabled;
                            discountCode.validFromDate = new Date(values.validFromDate);
                            discountCode.validToDate = new Date(values.validToDate);
                            discountCode.discountCodeType = values.discountCodeType;
                            discountCode.discountCode = values.discountCode;
                            discountCode.combinable = values.combinable;
                            discountCode.discountAmount = values.discountAmount;
                            discountCode.discountCodeApplicationType = values.discountCodeApplicationType;
                            discountCode.autoApply = values.autoApply;
                            discountCode.firstOrderOnly = values.firstOrderOnly;
                            discountCode.usesPerCustomer = values.usesPerCustomer;
                            discountCode.expression = discountCode.expression;
                            if (!discountCode.id) return [3 /*break*/, 2];
                            return [4 /*yield*/, axios.patch("".concat(discountCodesUrl, "/").concat(discountCode.id), discountCode)
                                    .then(function (response) {
                                    setDiscountCode(response.data);
                                    window.location.href = "".concat(discountCodesUrl, "/").concat(response.data.id, "/edit");
                                })
                                    .catch(function (error) {
                                    setErrorMessage("Failed To Save Discount Code: ".concat(error));
                                })];
                        case 1:
                            _a.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, axios.post("".concat(discountCodesUrl), discountCode)
                                .then(function (response) {
                                setDiscountCode(response.data);
                                window.location.href = "".concat(discountCodesUrl, "/").concat(response.data.id, "/edit");
                            })
                                .catch(function (error) {
                                setErrorMessage("Failed To Create New Discount Code: ".concat(error));
                            })];
                        case 3:
                            _a.sent();
                            _a.label = 4;
                        case 4:
                            setShouldSubmitForm(false);
                            return [2 /*return*/];
                    }
                });
            }); }, initialValues: {
                name: discountCode.name || '',
                discountCode: discountCode.discountCode || '',
                enabled: discountCode.enabled || false,
                validFromDate: discountCode.validFromDate ? moment(new Date(discountCode.validFromDate)).format('YYYY-MM-DD') : moment(new Date).format('YYYY-MM-DD'),
                validToDate: discountCode.validToDate ? moment(new Date(discountCode.validToDate)).format('YYYY-MM-DD') : moment(new Date).format('YYYY-MM-DD'),
                discountCodeType: discountCode.discountCodeType || DiscountCodeType.FIXED,
                discountAmount: discountCode.discountAmount,
                autoApply: discountCode.autoApply || false,
                firstOrderOnly: discountCode.firstOrderOnly || false,
                combinable: discountCode.combinable || false,
                discountCodeApplicationType: discountCode.discountCodeApplicationType || DiscountCodeApplicationType.ITEM,
                usesPerCustomer: discountCode.usesPerCustomer || 1
            } }, function (_a) {
            var handleSubmit = _a.handleSubmit, handleChange = _a.handleChange, handleBlur = _a.handleBlur, values = _a.values, touched = _a.touched, errors = _a.errors, isSubmitting = _a.isSubmitting;
            return (React.createElement(Form, { noValidate: true, onSubmit: handleSubmit },
                errorMessage &&
                    React.createElement(Alert, { variant: "danger", className: "mb-3" }, errorMessage),
                React.createElement(Card, { className: "mb-3" },
                    React.createElement(Card.Header, null, "Details"),
                    React.createElement(Card.Body, null,
                        React.createElement(Row, { className: "mb-3" },
                            React.createElement(Col, { className: "col col-md-12 col-lg-4" },
                                React.createElement(Form.Group, { as: Col, md: "12", controlId: "name" },
                                    React.createElement(Form.Label, null, "Name"),
                                    React.createElement(FormControl, { type: "text", name: "name", value: values.name, onChange: handleChange, onBlur: handleBlur, isInvalid: touched.name && !!errors.name }),
                                    React.createElement(Form.Control.Feedback, { type: "invalid" }, errors.name),
                                    React.createElement(Form.Text, { id: "nameHelpBlock", muted: true }, "A user friendly name/description for the discount e.g. 10% off flyers")))),
                        React.createElement(Row, { className: "mb-3" },
                            React.createElement(Col, { className: "col col-md-12 col-lg-4" },
                                React.createElement(Form.Group, { as: Col, md: "12", controlId: "discountCode" },
                                    React.createElement(Form.Label, null, "Discount Code"),
                                    React.createElement(FormControl, { type: "text", name: "discountCode", value: values.discountCode, onChange: function (e) {
                                            var uppercaseValue = e.target.value.toUpperCase();
                                            handleChange({
                                                target: {
                                                    name: e.target.name,
                                                    value: uppercaseValue,
                                                },
                                            });
                                        }, onBlur: handleBlur, isInvalid: touched.discountCode && !!errors.discountCode }),
                                    React.createElement(Form.Control.Feedback, { type: "invalid" }, errors.discountCode),
                                    React.createElement(Form.Text, { id: "discountCodeHelpBlock", muted: true }, "The discount code that the user will enter to apply the discount")))),
                        React.createElement(Row, { className: "mb-3" },
                            React.createElement(Col, { className: "col col-md-12 col-lg-4" },
                                React.createElement(Form.Group, { as: Col, md: "12", controlId: "enabled" },
                                    React.createElement(Form.Label, null, "Enabled"),
                                    React.createElement(Form.Select, { name: "enabled", onChange: handleChange, onBlur: handleBlur, value: values.enabled.toString(), isInvalid: touched.enabled && !!errors.enabled },
                                        React.createElement("option", { value: "true" }, "Yes"),
                                        React.createElement("option", { value: "false" }, "No")),
                                    React.createElement(Form.Control.Feedback, { type: "invalid" }, errors.discountCode),
                                    React.createElement(Form.Text, { id: "enabledHelpBlock", muted: true }, "Indicates if the discount code is enabled or disabled")))),
                        React.createElement(Row, { className: "mb-3" },
                            React.createElement(Col, { className: "col col-md-12 col-lg-4" },
                                React.createElement(Form.Group, { as: Col, md: "12", controlId: "validFromDate" },
                                    React.createElement(Form.Label, null, "Valid From Date"),
                                    React.createElement(FormControl, { type: "date", name: "validFromDate", value: values.validFromDate, onChange: handleChange, onBlur: handleBlur, isInvalid: touched.validFromDate && !!errors.validFromDate }),
                                    React.createElement(Form.Control.Feedback, { type: "invalid" }, errors.validFromDate),
                                    React.createElement(Form.Text, { id: "validFromDateHelpBlock", muted: true }, "The start date that this discount code is valid from")))),
                        React.createElement(Row, { className: "mb-3" },
                            React.createElement(Col, { className: "col col-md-12 col-lg-4" },
                                React.createElement(Form.Group, { as: Col, md: "12", controlId: "validToDate" },
                                    React.createElement(Form.Label, null, "Valid To Date"),
                                    React.createElement(FormControl, { type: "date", name: "validToDate", value: values.validToDate, onChange: handleChange, onBlur: handleBlur, isInvalid: touched.validToDate && !!errors.validToDate }),
                                    React.createElement(Form.Control.Feedback, { type: "invalid" }, errors.validToDate),
                                    React.createElement(Form.Text, { id: "validToDateHelpBlock", muted: true }, "The end date that this discount code is valid to")))))),
                React.createElement(Card, { className: "mb-3" },
                    React.createElement(Card.Header, null, "Discount"),
                    React.createElement(Card.Body, null,
                        React.createElement(Row, { className: "mb-3" },
                            React.createElement(Col, { className: "col col-md-12 col-lg-4" },
                                React.createElement(Form.Group, { as: Col, md: "12", controlId: "discountCodeType" },
                                    React.createElement(Form.Label, null, "Discount Type"),
                                    React.createElement(Form.Select, { name: "discountCodeType", onChange: handleChange, onBlur: handleBlur, value: values.discountCodeType, isInvalid: touched.discountCodeType && !!errors.discountCodeType },
                                        React.createElement("option", { value: DiscountCodeType.FIXED }, "Fixed Amount"),
                                        React.createElement("option", { value: DiscountCodeType.PERCENTAGE }, "Percentage")),
                                    React.createElement(Form.Control.Feedback, { type: "invalid" }, errors.discountCodeType),
                                    React.createElement(Form.Text, { id: "discountCodeTypeHelpBlock", muted: true }, "The type of discount to be applied to order/item")))),
                        values.discountCodeType !== DiscountCodeType.FREE_DELIVERY && (React.createElement(Row, { className: "mb-3" },
                            React.createElement(Col, { className: "col col-md-12 col-lg-4" },
                                React.createElement(Form.Group, { as: Col, md: "12", controlId: "discountAmount" },
                                    React.createElement(Form.Label, null, "Discount Amount"),
                                    React.createElement(FormControl, { type: "number", name: "discountAmount", value: values.discountAmount, onChange: handleChange, onBlur: handleBlur, isInvalid: touched.discountAmount && !!errors.discountAmount, step: "0.01", min: "0" }),
                                    React.createElement(Form.Control.Feedback, { type: "invalid" }, errors.discountAmount),
                                    React.createElement(Form.Text, { id: "discountAmountHelpBlock", muted: true }, "The amount of discount to be applied to the order/item"))))),
                        React.createElement(Row, { className: "mb-3" },
                            React.createElement(Col, { className: "col col-md-12 col-lg-4" },
                                React.createElement(Form.Group, { as: Col, md: "12", controlId: "autoApply" },
                                    React.createElement(Form.Label, null, "Auto Apply"),
                                    React.createElement(Form.Select, { name: "autoApply", onChange: handleChange, onBlur: handleBlur, value: values.autoApply.toString(), isInvalid: touched.autoApply && !!errors.autoApply },
                                        React.createElement("option", { value: "true" }, "Yes"),
                                        React.createElement("option", { value: "false" }, "No")),
                                    React.createElement(Form.Control.Feedback, { type: "invalid" }, errors.autoApply),
                                    React.createElement(Form.Text, { id: "autoApplyHelpBlock", muted: true }, "Apply this discount automatically without requiring the user to input a discount code")))),
                        values.autoApply !== "true" && (React.createElement(Row, { className: "mb-3" },
                            React.createElement(Col, { className: "col col-md-12 col-lg-4" },
                                React.createElement(Form.Group, { as: Col, md: "12", controlId: "usesPerCustomer" },
                                    React.createElement(Form.Label, null, "Uses Per Customer"),
                                    React.createElement(FormControl, { type: "number", name: "usesPerCustomer", value: values.usesPerCustomer, onChange: handleChange, onBlur: handleBlur, isInvalid: touched.usesPerCustomer && !!errors.usesPerCustomer, step: "1", min: "1" }),
                                    React.createElement(Form.Control.Feedback, { type: "invalid" }, errors.usesPerCustomer),
                                    React.createElement(Form.Text, { id: "usesPerCustomerHelpBlock", muted: true }, "The number of times a customer can use this discount code. Not applicable when auto apply is enabled"))))),
                        React.createElement(Row, { className: "mb-3" },
                            React.createElement(Col, { className: "col col-md-12 col-lg-4" },
                                React.createElement(Form.Group, { as: Col, md: "12", controlId: "firstOrderOnly" },
                                    React.createElement(Form.Label, null, "First Order Only"),
                                    React.createElement(Form.Select, { name: "firstOrderOnly", onChange: handleChange, onBlur: handleBlur, value: values.firstOrderOnly.toString(), isInvalid: touched.firstOrderOnly && !!errors.firstOrderOnly },
                                        React.createElement("option", { value: "true" }, "Yes"),
                                        React.createElement("option", { value: "false" }, "No")),
                                    React.createElement(Form.Control.Feedback, { type: "invalid" }, errors.firstOrderOnly),
                                    React.createElement(Form.Text, { id: "firstOrderOnlyHelpBlock", muted: true }, "Indicates if the code is applicable to first orders only. Customers must be logged in to use type of discount code")))),
                        React.createElement(Row, { className: "mb-3" },
                            React.createElement(Col, { className: "col col-md-12 col-lg-4" },
                                React.createElement(Form.Group, { as: Col, md: "12", controlId: "combinable" },
                                    React.createElement(Form.Label, null, "Combinable"),
                                    React.createElement(Form.Select, { name: "combinable", onChange: handleChange, onBlur: handleBlur, value: values.combinable.toString(), isInvalid: touched.combinable && !!errors.combinable },
                                        React.createElement("option", { value: "true" }, "Yes"),
                                        React.createElement("option", { value: "false" }, "No")),
                                    React.createElement(Form.Control.Feedback, { type: "invalid" }, errors.combinable),
                                    React.createElement(Form.Text, { id: "combinableHelpBlock", muted: true }, "Indicates if this discount can be used in conjunction with other discount codes")))))),
                React.createElement(Card, { className: "mb-3" },
                    React.createElement(Card.Header, null, "Criteria"),
                    React.createElement(Card.Body, null,
                        React.createElement(Row, { className: "mb-3" },
                            React.createElement(Col, { className: "col col-md-12 col-lg-4" },
                                React.createElement(Form.Group, { as: Col, md: "12", controlId: "discountCodeApplicationType" },
                                    React.createElement(Form.Label, null, "Applies To"),
                                    React.createElement(Form.Select, { name: "discountCodeApplicationType", onChange: function (e) {
                                            var _a;
                                            handleChange(e);
                                            (_a = discountCodeRuleEditorRef.current) === null || _a === void 0 ? void 0 : _a.changeApplicationType(e.target.value);
                                        }, onBlur: handleBlur, value: values.discountCodeApplicationType, isInvalid: touched.discountCodeApplicationType && !!errors.discountCodeApplicationType },
                                        React.createElement("option", { value: DiscountCodeApplicationType.ITEM }, "Matching Items")),
                                    React.createElement(Form.Control.Feedback, { type: "invalid" }, errors.discountCodeApplicationType),
                                    React.createElement(Form.Text, { id: "discountCodeApplicationTypeHelpBlock", muted: true }, "Indicates the type of object this object that this discount applies to")))),
                        React.createElement(Row, { className: "mb-3" },
                            React.createElement(Col, { className: "col col-lg-12" },
                                React.createElement(Form.Group, { as: Col, md: "12", controlId: "discountRule" },
                                    React.createElement(Form.Label, null, "Discount Rule"),
                                    React.createElement(DiscountCodeRuleEditor, { discountCode: discountCode, ref: discountCodeRuleEditorRef }),
                                    React.createElement(Form.Text, { id: "discountRuleHelpBlock", muted: true }, "The rule that is used to match applicable orders or items that the discount will be applied to")))))),
                React.createElement(Row, { className: "mt-3" },
                    React.createElement(Col, null,
                        isSubmitting &&
                            React.createElement(Button, { type: "submit", variant: "primary", disabled: true },
                                React.createElement(Spinner, { as: "span", animation: "grow", size: "sm", role: "status", "aria-hidden": "true" }),
                                "Saving..."),
                        !isSubmitting &&
                            React.createElement(Button, { type: "submit", variant: "primary", disabled: errors > 0, onClick: function () { return setShouldSubmitForm(true); } }, "Save")))));
        }))); };
    return (React.createElement("div", null, renderDiscountCodeForm()));
};
export default DiscountCodeForm;
