var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState } from 'react';
import { Form as FormikForm, Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { Alert, Button, Col, Form, Modal, Row, Table } from 'react-bootstrap';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { BindingTypeLabel } from '../../types';
import { BindingType, Trilean } from '@mixam-platform/mixam-types';
import DraggableRow from './BindingTypeOptions/DraggableRow';
import RequiredSubstrateEditor from './BindingTypeOptions/RequiredSubstrateEditor';
import BindingColourOptionsEditor from './BindingTypeOptions/BindingColourOptionsEditor';
var BindingTypeOptionsEditor = function (_a) {
    var isEditable = _a.isEditable, bindingColours = _a.bindingColours, substrateTypes = _a.substrateTypes;
    var _b = useFormikContext(), values = _b.values, setFieldValue = _b.setFieldValue;
    var _c = useState(false), showModal = _c[0], setShowModal = _c[1];
    var _d = useState(null), editIndex = _d[0], setEditIndex = _d[1];
    var options = values.bindingTypeOptions || [];
    var moveRow = function (dragIndex, hoverIndex) {
        var updatedOptions = __spreadArray([], options, true);
        var removed = updatedOptions.splice(dragIndex, 1)[0];
        updatedOptions.splice(hoverIndex, 0, removed);
        setFieldValue('bindingTypeOptions', updatedOptions);
    };
    var initialModalValues = {
        bindingType: BindingType.STAPLED,
        label: BindingTypeLabel.DEFAULT,
        requiredSubstrate: undefined,
        defaultPages: 8,
        santaDefault: false,
        supportsEndPapers: false,
        supportsDustJackets: false,
        supportsRibbons: false,
        supportsHeadAndTailBands: false,
        bindingColourOptions: [],
        sewing: Trilean.UNAVAILABLE,
        preDrilledHoles: Trilean.UNAVAILABLE,
        separateCover: Trilean.UNAVAILABLE,
    };
    var validationSchema = Yup.object().shape({
        bindingType: Yup.string().required('Binding Type is required'),
        requiredSubstrate: Yup.object().nullable(),
        defaultPages: Yup.number()
            .required('Default Pages is required')
            .min(4, 'Must be at least 4'),
        santaDefault: Yup.boolean(),
        supportsEndPapers: Yup.boolean(),
        supportsDustJackets: Yup.boolean(),
        supportsRibbons: Yup.boolean(),
        supportsHeadAndTailBands: Yup.boolean(),
        bindingColourOptions: Yup.array()
            .of(Yup.object().shape({
            bindingColour: Yup.string().required('Binding Colour is required'),
            santaDefault: Yup.boolean(),
        }))
            .nullable()
            .test('one-default', 'At least one binding colour option must be set as default', function (bindingColourOptions) {
            if (!bindingColourOptions || bindingColourOptions.length === 0) {
                return true; // No options provided, validation passes
            }
            return bindingColourOptions.some(function (opt) { return opt.santaDefault; });
        }),
        sewing: Yup.string().required('Sewing is required'),
        preDrilledHoles: Yup.string().required('Pre-drilled Holes is required'),
        separateCover: Yup.string().required('Separate Cover is required'),
    });
    var handleAddOrEditOption = function (option) {
        var updatedOptions = __spreadArray([], options, true);
        if (editIndex !== null) {
            updatedOptions[editIndex] = option;
        }
        else {
            updatedOptions.push(option);
        }
        // If santaDefault is true, unset other options
        if (option.santaDefault) {
            updatedOptions.forEach(function (opt, idx) {
                if (idx !== (editIndex !== null ? editIndex : updatedOptions.length - 1)) {
                    opt.santaDefault = false;
                }
            });
        }
        setFieldValue('bindingTypeOptions', updatedOptions);
        setShowModal(false);
        setEditIndex(null);
    };
    var handleEditClick = function (index) {
        setEditIndex(index);
        setShowModal(true);
    };
    // Function to get all error messages from nested error objects
    var getErrorMessages = function (errors) {
        var messages = [];
        var extractErrors = function (errObj) {
            Object.values(errObj).forEach(function (value) {
                if (typeof value === 'string') {
                    messages.push(value);
                }
                else if (typeof value === 'object' && value !== null) {
                    extractErrors(value);
                }
            });
        };
        extractErrors(errors);
        return messages;
    };
    return (React.createElement("div", { className: "mb-4" },
        React.createElement("h5", null, "Binding Type Options"),
        React.createElement(DndProvider, { backend: HTML5Backend },
            React.createElement(Table, { bordered: true, hover: true, variant: "light" },
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("th", null, "Binding Type"),
                        React.createElement("th", null, "Sewing"),
                        React.createElement("th", null, "Pre-drilled Holes"),
                        React.createElement("th", null, "Separate Cover"),
                        React.createElement("th", null, "Default"),
                        React.createElement("th", null))),
                React.createElement("tbody", null, options.length > 0 ? (options.map(function (option, index) { return (React.createElement(DraggableRow, { key: "binding-type-option-".concat(option.bindingType, "-").concat(option.label, "-").concat(option.santaDefault), index: index, option: option, moveRow: moveRow, setFieldValue: setFieldValue, options: options, isEditable: isEditable, onEdit: handleEditClick })); })) : (React.createElement("tr", null,
                    React.createElement("td", { colSpan: 6, className: "text-center" }, "No binding type options available.")))))),
        React.createElement("div", { className: "text-end" },
            React.createElement(Button, { variant: "secondary", onClick: function () {
                    setEditIndex(null);
                    setShowModal(true);
                }, disabled: !isEditable }, "Add Binding Type Option")),
        React.createElement(Modal, { show: showModal, onHide: function () {
                setShowModal(false);
                setEditIndex(null);
            }, size: "lg" },
            React.createElement(Formik, { initialValues: editIndex !== null
                    ? __assign(__assign({}, initialModalValues), options[editIndex]) : initialModalValues, validationSchema: validationSchema, onSubmit: function (values, _a) {
                    var setSubmitting = _a.setSubmitting;
                    handleAddOrEditOption(values);
                    setSubmitting(false);
                } }, function (_a) {
                var values = _a.values, handleChange = _a.handleChange, handleSubmit = _a.handleSubmit, errors = _a.errors, touched = _a.touched, setFieldValue = _a.setFieldValue, isSubmitting = _a.isSubmitting, submitCount = _a.submitCount;
                return (React.createElement(React.Fragment, null,
                    React.createElement(Modal.Header, { closeButton: true },
                        React.createElement(Modal.Title, null, editIndex !== null
                            ? 'Edit Binding Type Option'
                            : 'Add New Binding Type Option')),
                    React.createElement(Modal.Body, null,
                        submitCount > 0 && Object.keys(errors).length > 0 && (React.createElement(Alert, { variant: "danger" }, getErrorMessages(errors).map(function (err, idx) { return (React.createElement("div", { key: "binding-type-error-".concat(idx) }, err)); }))),
                        React.createElement(FormikForm, null,
                            React.createElement(Row, null,
                                React.createElement(Col, { md: 4 },
                                    React.createElement(Form.Group, { className: "mb-3" },
                                        React.createElement(Form.Label, null, "Binding Type"),
                                        React.createElement(Form.Control, { as: "select", name: "bindingType", value: values.bindingType, onChange: handleChange, isInvalid: touched.bindingType && !!errors.bindingType },
                                            React.createElement("option", { value: "" }, "Select Binding Type"),
                                            Object.values(BindingType).map(function (type) { return (React.createElement("option", { key: "binding-type-".concat(type), value: type }, type)); })),
                                        React.createElement(Form.Control.Feedback, { type: "invalid" }, errors.bindingType))),
                                React.createElement(Col, { md: 4 },
                                    React.createElement(Form.Group, { className: "mb-3" },
                                        React.createElement(Form.Label, null, "Label"),
                                        React.createElement(Form.Control, { as: "select", name: "label", value: values.label, onChange: handleChange, isInvalid: touched.label && !!errors.label }, Object.values(BindingTypeLabel).map(function (label) { return (React.createElement("option", { key: "binding-type-label-".concat(label), value: label }, label)); })),
                                        React.createElement(Form.Control.Feedback, { type: "invalid" }, errors.label))),
                                React.createElement(Col, { md: 4 },
                                    React.createElement(Form.Group, { className: "mb-3" },
                                        React.createElement(Form.Label, null, "Default Pages"),
                                        React.createElement(Form.Control, { type: "number", name: "defaultPages", value: values.defaultPages, onChange: handleChange, isInvalid: touched.defaultPages && !!errors.defaultPages, min: 4, step: 4 }),
                                        React.createElement(Form.Control.Feedback, { type: "invalid" }, errors.defaultPages)))),
                            React.createElement(Row, null,
                                React.createElement(Col, { md: 4 },
                                    React.createElement(Form.Group, { className: "mb-3" },
                                        React.createElement(Form.Label, null, "Sewing"),
                                        React.createElement(Form.Control, { as: "select", name: "sewing", value: values.sewing, onChange: handleChange, isInvalid: touched.sewing && !!errors.sewing },
                                            React.createElement("option", { value: "" }, "Select Sewing Option"),
                                            Object.values(Trilean).map(function (value) { return (React.createElement("option", { key: "sewing-trilean-".concat(value), value: value }, value)); })),
                                        React.createElement(Form.Control.Feedback, { type: "invalid" }, errors.sewing))),
                                React.createElement(Col, { md: 4 },
                                    React.createElement(Form.Group, { className: "mb-3" },
                                        React.createElement(Form.Label, null, "Pre-drilled Holes"),
                                        React.createElement(Form.Control, { as: "select", name: "preDrilledHoles", value: values.preDrilledHoles, onChange: handleChange, isInvalid: touched.preDrilledHoles &&
                                                !!errors.preDrilledHoles },
                                            React.createElement("option", { value: "" }, "Select Option"),
                                            Object.values(Trilean).map(function (value) { return (React.createElement("option", { key: "pre-drilled-hole-trilean-".concat(value), value: value }, value)); })),
                                        React.createElement(Form.Control.Feedback, { type: "invalid" }, errors.preDrilledHoles))),
                                React.createElement(Col, { md: 4 },
                                    React.createElement(Form.Group, { className: "mb-3" },
                                        React.createElement(Form.Label, null, "Separate Cover"),
                                        React.createElement(Form.Control, { as: "select", name: "separateCover", value: values.separateCover, onChange: handleChange, isInvalid: touched.separateCover &&
                                                !!errors.separateCover },
                                            React.createElement("option", { value: "" }, "Select Option"),
                                            Object.values(Trilean).map(function (value) { return (React.createElement("option", { key: "separate-cover-trilean-".concat(value), value: value }, value)); })),
                                        React.createElement(Form.Control.Feedback, { type: "invalid" }, errors.separateCover)))),
                            React.createElement("hr", null),
                            React.createElement(Row, null,
                                React.createElement(Col, { md: 6 },
                                    React.createElement(Form.Group, { className: "mb-3" },
                                        React.createElement(Form.Check, { type: "checkbox", label: "Supports Endpapers", name: "supportsEndPapers", checked: values.supportsEndPapers, onChange: handleChange }))),
                                React.createElement(Col, { md: 6 },
                                    React.createElement(Form.Group, { className: "mb-3" },
                                        React.createElement(Form.Check, { type: "checkbox", label: "Supports Dust Jackets", name: "supportsDustJackets", checked: values.supportsDustJackets, onChange: handleChange })))),
                            React.createElement(Row, null,
                                React.createElement(Col, { md: 6 },
                                    React.createElement(Form.Group, { className: "mb-3" },
                                        React.createElement(Form.Check, { type: "checkbox", label: "Supports Ribbons", name: "supportsRibbons", checked: values.supportsRibbons, onChange: handleChange }))),
                                React.createElement(Col, { md: 6 },
                                    React.createElement(Form.Group, { className: "mb-3" },
                                        React.createElement(Form.Check, { type: "checkbox", label: "Supports Head and Tail Bands", name: "supportsHeadAndTailBands", checked: values.supportsHeadAndTailBands, onChange: handleChange })))),
                            React.createElement("hr", null),
                            React.createElement(RequiredSubstrateEditor, { isEditable: isEditable, substrateTypes: substrateTypes, requiredSubstrate: values.requiredSubstrate, setFieldValue: setFieldValue }),
                            React.createElement(BindingColourOptionsEditor, { bindingColours: bindingColours, values: values, setFieldValue: setFieldValue, isEditable: isEditable }),
                            React.createElement(Form.Group, { className: "mb-3" },
                                React.createElement(Form.Check, { type: "checkbox", label: "Set as Default", name: "santaDefault", checked: values.santaDefault, onChange: function (e) {
                                        setFieldValue('santaDefault', e.target.checked);
                                    } })))),
                    React.createElement(Modal.Footer, null,
                        React.createElement(Button, { variant: "secondary", onClick: function () {
                                setShowModal(false);
                                setEditIndex(null);
                            } }, "Close"),
                        React.createElement(Button, { variant: "primary", onClick: function () { return handleSubmit(); }, disabled: !isEditable || isSubmitting },
                            editIndex !== null ? 'Update' : 'Add',
                            " Binding Type Option"))));
            }))));
};
export default BindingTypeOptionsEditor;
